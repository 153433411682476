import { Component } from '@angular/core';
import { Resort } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';
import { pages } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { MemberService } from '@member/member.service';
import { SearchService } from '@shared/services/search/search.service';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';
import { TenantService } from '@shared/services/tenant.service';

@Component({
  selector: 'app-header-v2',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: false
})
export class HeaderV2Component extends ComponentBase {
  resort?: Resort;
  guestsCount = 0;
  resortLogoImageURL?: string;
  memberName?: string;
  profile = this._member.profile;
  membershipEnabled = this._tenant.membershipEnabled;

  constructor(
    private readonly _sidebar: SidebarService,
    private readonly _tenant: TenantService,
    private readonly _member: MemberService,
    search: SearchService,
    services: ComponentBaseService,
  ) {
    super(services);

    this.initMember();
    super.pushSub(
      _member.onMemberChanged(() => {
        this.initMember();
      }),
      search.setWhenContextChanged(({ resort }) => {
        this.resort = resort;
        this.resortLogoImageURL = this.resort?.logo?.thumbnailUrl;
        this.guestsCount = search.getTotalGuests();
      })
    );
  }

  initMember() {
    this.profile = this._member.profile;
    this.memberName = this.profile?.firstName || this.profile?.email;
  }

  openProfileMenu() {
    this._sidebar.openAccount();
  }

  openGuestDetails() {
    if (this.isOnSearchPage) {
      this._sidebar.openHotelFilter();
    }
  }

  openMember() {
    this._sidebar.openMember();
  }

  toSearchV1() {
    if (this._tenant.isTestTenant) {
      this.services.navigation.open(pages.search);
    }
  }
}
