import { Component } from '@angular/core';
import { Airport, AirportTransfer, Journey, NonMonetaryDiscount, Package, Resort, RoomType, SpecialOffer } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';
import { cartAddOn } from '@shared/consts/animations';
import { tripProtectionCategory } from '@shared/consts/common';
import { AddOnToPurchaseViewModel, WithId } from '@shared/models/common';
import { AddOnService } from '@shared/services/add-on.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { MemberService } from '@member/member.service';
import { PackageService } from '@shared/services/package.service';
import { PricingService } from '@shared/services/pricing.service';
import { SearchService } from '@shared/services/search/search.service';
import { TenantService } from '@shared/services/tenant.service';
import { TrackingService } from '@shared/services/tracking.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-cart-viewer-v2',
  templateUrl: './cart-viewer.component.html',
  animations: [cartAddOn.enter, cartAddOn.leave],
  standalone: false
})
export class CartViewerV2Component extends ComponentBase {
  constructor(
    private readonly _search: SearchService,
    private readonly _pricing: PricingService,
    private readonly _tracking: TrackingService,
    private readonly _package: PackageService,
    private readonly _tenant: TenantService,
    private readonly _member: MemberService,
    readonly addOn: AddOnService,
    services: ComponentBaseService,
  ) {
    super(services);

    this.pushSub(
      _search.setWhenStateChanged(() => this._initSearchData()),
      _package.setWhenDataChanged(() => this._initPackageData()),
      addOn.setWhenDataChanged(result => {
        this._trackChanges();
        this.addOns = result.toPurchaseViewModel;
      }),
      _pricing.setWhenDataChanged(data => {
        this.saved = data.saved;
        this.baseTotal = data.saved + data.total;
        this.memmberSaved = data.memberSaved;
      }),
      _member.onMemberChanged(() => this.profile = _member.profile)
    );
  }

  //#region Flights
  isOpenedFlights = false;
  arrivalAirport?: Airport;
  departureAirport?: Airport;
  inboundJourney?: Journey;
  outboundJourney?: Journey;
  //#endregion

  //#region  Hotel
  isOpenedResort = false;
  resort?: Resort;
  roomType?: RoomType;
  adults = 0;
  children = 0;
  arrival?: DateTime
  departure?: DateTime
  periodNights!: number;
  //#endregion

  //#region Transfer
  inboundTransfer?: AirportTransfer;
  inboundTransferTotal = 0;

  outboundTransfer?: AirportTransfer;
  outboundTransferTotal = 0;
  //#endregion

  //#region AddOns
  addOns: AddOnToPurchaseViewModel[] = [];
  tripProtectionCategory = tripProtectionCategory;
  isCouplesTenant = this._tenant.isCouplesTenant;
  //#endregion

  //#region Price
  selectedPackage?: WithId<Package>;
  specialOffer?: SpecialOffer;
  nonMonetary: NonMonetaryDiscount[] = [];

  flightTotal = 0;
  roomTotal = 0;
  addOnsTotal = 0;

  baseTotal = 0;
  saved = 0;
  memmberSaved = 0;

  profile = this._member.profile;
  //#endregion

  //#region Init
  private _initPackageData() {
    this._trackChanges();

    const data = this._package;

    this.roomType = data.roomType;
    this.resort = data.resort;
    this.selectedPackage = data.selectedPackage;
    this.specialOffer = data.specialOffer;
    this.inboundJourney = data.flight?.itinerary?.inboundJourney;
    this.outboundJourney = data.flight?.itinerary?.outboundJourney;
    this.periodNights = data.periodNights;

    this.inboundTransfer = data.inboundAirportTransfer;
    this.inboundTransferTotal = this._pricing.inboundAirportTransferTotal;

    this.outboundTransfer = data.outboundAirportTransfer;
    this.outboundTransferTotal = this._pricing.outboundAirportTransferTotal;
    this.nonMonetary = this._package.getNonMonetaryDiscounts(data.selectedPackage);
  }

  private _initSearchData() {
    this._trackChanges();
    const { fromDate, toDate, arrivalAirport, departureAirport } = this._search.state.context;

    this.adults = this._search.getTotalAdults();
    this.children = this._search.getTotalChildren();
    this.arrival = fromDate;
    this.departure = toDate;
    this.arrivalAirport = arrivalAirport;
    this.departureAirport = departureAirport;
  }
  //#endregion

  //#region Tracking
  private _trackChanges() {
    if (this.isOnConfirmationPage) {
      return;
    }

    const { roomType, selectedPackage, inboundAirportTransfer, outboundAirportTransfer } = this._package;

    if (this.roomType?.roomTypeId !== roomType?.roomTypeId
      || this.roomTotal !== this._pricing.roomTotal) {
      this._tracking.updateRoomType();
    }

    if (this.selectedPackage?.id !== selectedPackage?.id
      || this.flightTotal !== this._pricing.flightTotal) {
      this._tracking.updateFlight();
    }

    if (this.inboundTransfer?.id !== inboundAirportTransfer?.id
      || this.inboundTransferTotal !== this._pricing.inboundAirportTransferTotal) {
      this._tracking.updateInboundAirportTransfer();
    }

    if (this.outboundTransfer?.id !== outboundAirportTransfer?.id
      || this.outboundTransferTotal !== this._pricing.outboundAirportTransferTotal) {
      this._tracking.updateOutboundAirportTransfer();
    }

    if (this.addOnsTotal !== this._pricing.addOnsTotal) {
      this._tracking.updateAddons();
    }

    this._setTrackingData();
  }

  private _setTrackingData() {
    this.flightTotal = this._pricing.flightTotal;
    this.roomTotal = this._pricing.roomTotal;
    this.addOnsTotal = this._pricing.addOnsTotal;
  }
  //#endregion

  //#region Toggle
  toggleFlights() {
    this.isOpenedFlights = !this.isOpenedFlights;
  }

  toggleHotel() {
    this.isOpenedResort = !this.isOpenedResort;
  }
  //#endregion
}
