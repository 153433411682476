import { Component, HostBinding } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { MemberService } from '@member/member.service';
import { UpcomingBooking, IUpcomingBooking, IMemberBooking } from '@shared/api/be-api.generated';
import { BreadCrumbsItem } from '@shared/components-v2/common/bread-crumbs/bread-crumbs.component';
import { DateTime } from 'luxon';
import { arrayToRecord, getDiff } from '@shared/common';
import { DataService } from '@shared/services/data.service';

class ExtendedUpcomingBooking extends UpcomingBooking {
  constructor(data?: IUpcomingBooking) {
    super(data);

    if (data?.arrivalDate && data.departureDate) {
      this.nights = getDiff({ from: data.departureDate, to: data.arrivalDate }) || 0;
    }
  }

  nights!: number;
}

@Component({
  selector: 'app-member-bookings-v2',
  templateUrl: './member-bookings.component.html',
  standalone: false
})
export class MemberBookingsV2Component extends ComponentBase {
  constructor(
    private readonly _member: MemberService,
    private readonly _data: DataService,
    service: ComponentBaseService
  ) {
    super(service);
    this.init();
  }

  @HostBinding('class') hostClasses = ['w-100', 'flex-stretch-stretch', 'h-100-min'];
  bookings: ExtendedUpcomingBooking[] = [];
  isLoading = true;
  errorMessage?: string;
  breadCrumbs: BreadCrumbsItem[] = [{ caption: 'caption' }];
  bookingsPending: Record<string, string> = {};
  isOpened: Record<number, boolean> = {};
  isOpenedHistory: Record<number, boolean> = {};
  now = DateTime.now();
  selectedIndex = 0;

  history: IMemberBooking[] = [];
  airports: Record<string, string> = {}; //code -> full text
  resorts: Record<string, string> = {}; //rdp code -> caption
  rooms: Record<string, Record<string, string>> = {} // resort rdp code => room rdp code => caption

  init() {
    this.errorMessage = undefined;
    this.airports = {};
    this._data.values.resorts.forEach(resort => {
      if (resort.rdpCode) {
        this.resorts[resort.rdpCode || ''] = resort.name || '';
        this.rooms[resort.rdpCode || ''] = {};

        resort.roomTypes?.forEach(roomType => {
          if (roomType.shortCode) {
            this.rooms[resort.rdpCode || ''][roomType.shortCode || ''] = roomType.caption || '';
          }
        });
      }

      resort.airports?.forEach(airport => {
        if (airport?.code && !this.airports[airport.code] && airport.city && airport.name) {
          this.airports[airport.code] = airport.city + ' ' + airport.name;
        }
      })
    });

    this.pushSub(
      this._member.getBookings().subscribe({
        next: result => {
          this.bookings = result.upcoming?.map(item => new ExtendedUpcomingBooking(item)) ?? [];
          this.bookingsPending = arrayToRecord(this.bookings
            .filter(item => item.departureDate && item.departureDate > this.now)
            .map(item => item.bookingNumber || ''));
          this.bookings.forEach((_, index) => this.isOpened[index] = false);

          this.history = result.history ?? [];
          this.history.forEach((_, index) => this.isOpenedHistory[index] = false);
          this.history.forEach(item => item.points = 100);
        },
        error: ex => {
          this.errorMessage = this._member.getMembershipError(ex);
          this.isLoading = false;
        },
        complete: () => this.isLoading = false
      })
    );
  }

  toggleOpen(index: number) {
    this.isOpened[index] = !this.isOpened[index];
  }

  toggleOpenHistory(index: number) {
    this.isOpenedHistory[index] = !this.isOpenedHistory[index];
  }
}
