import { ChangeDetectionStrategy, Component, ContentChild, HostBinding, Input, TemplateRef } from '@angular/core';
import { Color, IndentSize, Position } from '@shared/models/common';

@Component({
  selector: 'app-modal-panel-v2',
  templateUrl: './modal-panel.component.html',
  styleUrls: ['./modal-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ModalPanelV2Component {
  classNames: string[] = [];

  @ContentChild('header') header?: TemplateRef<unknown>;
  @ContentChild('content') content?: TemplateRef<unknown>;
  @ContentChild('footer') footer?: TemplateRef<unknown>;

  @Input() @HostBinding('class.full-size') isFullSize = true;
  @HostBinding('class') get hostClasses() {
    return ['hide-if-empty', 'horizontal-' + this.positionHorizontal, 'vertical-' + this.positionVertical];
  }

  @Input() isActive = false;
  @Input() wrapClass = 'modal-panel-component';
  @Input() positionVertical: Position = 'center';
  @Input() positionHorizontal: Position = 'center';
  @Input() overlayColor: Color = 'modalBackground';
  @Input() paddingSize: IndentSize = 'default';
  @Input() background: Color = 'default';
  @Input() maxWidth = 400;
  @Input() unit = 'px';

  closeBanner(target: EventTarget | null) {
    if (!(target instanceof Element) || !target.closest('.' + this.wrapClass)) {
      this.hideBanner();
    }
  }

  hideBanner() {
    this.isActive = false;
  }
}
