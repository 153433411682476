@if(profile){
<ng-container *transloco="let t">
  <div class="flex-between-start flex-wrap flex-column-mobile-max gap-half">
    @if(!isShort){
    <div class="flex-column gap-half w-100">
      <div>
        {{t('member.menu.as')}}
      </div>
      <div class="fw-600 three-dots">
        {{profile.email}}
      </div>
    </div>
    }
    <div class="flex-center-between gap-half flex-wrap">
      <div class="color-textInactive fw-500">
        {{t('member.menu.loyaltyId', { value: profile.memberId })}}
      </div>
      <app-info-label
        [text]="t('member.banner.rewards')"
        type="memberDefault"
        textColor="memberText">
      </app-info-label>
    </div>
  </div>
</ng-container>
}