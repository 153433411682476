import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '@shared/services/data.service';

@Pipe({
  name: 'cabinType',
  standalone: false
})
export class CabinTypePipe implements PipeTransform {

  constructor(
    private readonly _data: DataService
  ) {
  }

  transform(cabinTypeCode: string | undefined, includeCode = true) {
    let result = this._data.values
      .cabinTypes.find(({ code }) => code === cabinTypeCode)?.name || '';

    if (includeCode && cabinTypeCode) {
      result += ` (${cabinTypeCode})`
    }

    return result;
  }
}
