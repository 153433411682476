<div class="page-container">
  <div class="page-content"
    [class.with-rate-plan-type-switcher]="header.availableRatePlanType">
    <app-header #header></app-header>
    <div class="under-header"></div>
    <app-search-bar></app-search-bar>
    <div class="under-search-bar" *ngIf="isRequestedFlights"></div>
    <app-notification-warnings class="page-section"></app-notification-warnings>
    <!--<app-notification-signup class="page-section"></app-notification-signup>-->
    <app-room-viewer></app-room-viewer>
    <app-flight-select></app-flight-select>
    <app-airport-transfer-select-options></app-airport-transfer-select-options>
    <app-notification-amenities></app-notification-amenities>
    <app-addon-select></app-addon-select>
    <app-cart-summary></app-cart-summary>
  </div>
  <app-footer></app-footer>
  <app-fixed-buttons></app-fixed-buttons>

  <ng-container *transloco="let t">
    <app-modal-panel-v2
      [isActive]="!!minimumStay"
      positionHorizontal="center"
      positionVertical="center"
      [maxWidth]="300"
      background="default">
      <ng-template #content>
        <div class="text-1-1-500 color-textPrimary"
          [innerHtml]="t('unavailability.minStay.content', { current: nights, required: minimumStay })">
        </div>
      </ng-template>
    </app-modal-panel-v2>

    <app-modal-panel-v2
      [isActive]="!!initialArrivalChanged"
      positionHorizontal="center"
      positionVertical="center"
      [maxWidth]="300"
      background="default">
      <ng-template #content>
        <div class="text-1_5-2-600">
          {{t('unavailability.initialArrivalChanged.header')}}
        </div>
        <div class="text-1-1-500 color-textPrimary"
          [innerHtml]="t('unavailability.initialArrivalChanged.content', { value: resortPhone })">
        </div>
      </ng-template>
    </app-modal-panel-v2>
  </ng-container>
</div>