import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Accommodation, Booking } from '@shared/api/be-api.generated';
import { buildResortAddress, createCarouselImages } from '@shared/common';
import { DataService } from '@shared/services/data.service';
import { ConfirmationService } from '@confirmation/services/confirmation.service';

@Component({
  selector: 'app-room-summary',
  templateUrl: './room-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class RoomSummaryComponent {
  booking = this._confirmation?.values.bookingDetails || new Booking();
  accommodation = this.booking?.accommodation || new Accommodation();

  confirmationNumber = this.booking.bookingNumber;
  arrivalDate = this.booking.arrivalDate;
  departureDate = this.booking.departureDate;
  guestsCount = this.booking.guestDetails?.length || 0;

  resort = this._data.values.resorts.find(resort => resort.resortId === this.accommodation.resortId);
  resortName = this.resort?.name || this.accommodation.resortName;
  resortAddress = buildResortAddress(this.resort?.address);

  roomType = this.resort?.roomTypes?.find(roomType => roomType.roomTypeId === this.accommodation.roomTypeId);
  roomName = this.roomType?.caption || this.accommodation.roomTypeCaption;
  roomDescription = this.roomType?.description;
  roomImages = createCarouselImages(this.roomType);
  firstRoomImageSrc = this.roomImages?.find(() => true)?.image;

  specialOffers = this.booking.pricing?.specialOffers;
  discount = this.booking.pricing?.discount || 0;

  nonMonetary = this.booking.pricing?.nonMonetaryDiscounts || [];

  constructor(
    private readonly _confirmation: ConfirmationService,
    private readonly _data: DataService,
  ) { }
}
