import { Component, HostBinding, Input } from '@angular/core';
import { LoaderSection } from '@shared/models/common';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss'],
  standalone: false
})
export class LoadingScreenComponent {
  @Input() section!: LoaderSection;
  @Input() resortLogo?: string;

  @HostBinding('class.full-screen-container') isFullScreen = true;
}
