import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-notification-prices',
  templateUrl: './notification-prices.component.html',
  styleUrls: ['./notification-prices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class NotificationPricesComponent {
}
