<ng-container *transloco="let t">
  <div class="flex-column bc-default padding w-100">
    <app-bread-crumbs-v2
      class="padding-top"
      [items]="breadCrumbs"
      section="member.bookings">
    </app-bread-crumbs-v2>
    <div class="text-1-1_5-400 margin-vertical">
      {{t('member.bookings.description')}}
    </div>
    @if (isLoading) {
    <div class="flex-column gap margin-top">
      <app-loading-icon size="button">
      </app-loading-icon>
      <div class="flex text-1-1-500 color-selected">
        {{t('member.bookings.loading')}}
      </div>
    </div>
    }
    @else if(!bookings.length) {
    @if (errorMessage) {
    <div class="flex-start error-message margin-right-auto"
      [innerHTML]="t('loader.tryAgain', { value: errorMessage })">
      <br>
    </div>
    }
    @else {
    <div class="flex text-1-1-500 color-selected">
      {{t('member.bookings.nothing')}}
    </div>
    }
    }
    @if (bookings.length) {
    <div class="padding text-1_125-1_25-500">
      {{t('member.bookings.upcoming')}}:
    </div>
    }
    @for (booking of bookings; track $index) {
    <div class="padding-horizontal"
      [class.border-left]="!isOpened[$index]"
      [class.border-selected-left]="isOpened[$index]">
      <div class="flex-start gap-quarter cursor-pointer"
        (click)="toggleOpen($index)">
        <app-icon circleSize="middle"
          [circle]="isOpened[$index] ? 'selected' : 'textInactive'">
          <span class="color-default text-1-1-500">{{isOpened[$index] ? '-' : '+'}}</span>
        </app-icon>
        <div class="flex-between flex-wrap full-size text-1_125-1_125-500 padding-vertical"
          [class.color-selected]="isOpened[$index]"
          [class.color-textInactive]="!isOpened[$index]">
          <div>
            {{
            t('cart.fromTo', {
            from: booking.arrivalDate | appDate: 'type4',
            to: booking.departureDate | appDate: 'type4'
            })
            }}
          </div>
          <div class="flex-between">
            <app-icon
              size="middle"
              filled="textPrimary"
              name="resort">
            </app-icon>
            <div class="color-textPrimary">
              <i>{{booking.resortName}}</i>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-between-start gap flex-wrap collapsable"
        [class.collapsed]="!isOpened[$index]">
        <div class="flex-column gap">
          <div>
            <div class="text-0_75-0_75-400 color-formControl">
              {{t('member.bookings.fields.bookedAt')}}:
            </div>
            <div class="text-1-1_5-500">
              {{booking.bookingDate | appDate: 'type4'}}
            </div>
          </div>
          <div>
            <div class="text-0_75-0_75-400 color-formControl">
              {{t('member.bookings.fields.bookingNumber')}}:
            </div>
            <div class="text-1-1_5-500">
              {{booking.bookingNumber}}
            </div>
          </div>
        </div>
        <div class="flex-column gap">
          <div>
            <div class="text-0_75-0_75-400 color-formControl">
              {{t('member.bookings.fields.arrival')}}:
            </div>
            <div class="flex-between">
              <div class="text-1-1_5-500">
                {{booking.arrivalDate | appDate: 'type3'}}
              </div>
            </div>
          </div>
          <div>
            <div class="text-0_75-0_75-400 color-formControl">
              {{t('member.bookings.fields.departure')}}:
            </div>
            <div class="flex-between">
              <div class="text-1-1_5-500">
                {{booking.departureDate | appDate: 'type3'}}
              </div>
            </div>
          </div>
        </div>
        <div class="flex-column gap">
          <div>
            <div class="text-0_75-0_75-400 color-formControl">
              {{t('member.bookings.fields.resort')}}:
            </div>
            <div class="flex-between">
              <div class="text-1-1_5-500">
                {{booking.resortName}}
              </div>
            </div>
          </div>
          <div>
            <div class="text-0_75-0_75-400 color-formControl">
              {{t('member.bookings.fields.room')}}:
            </div>
            <div class="flex-between">
              <div class="text-1-1_5-500">
                {{booking.roomTypeCaption}}
              </div>
            </div>
          </div>
        </div>
        <div class="flex-column gap">
          <div>
            <div class="text-0_75-0_75-400 color-formControl">
              {{t('member.bookings.fields.guests')}}:
            </div>
            <div class="flex-start">
              @for (guest of booking.persons; track $index) {
              <div class="text-1-1_5-500">
                {{guest}}@if ($index !== ((booking.persons?.length ?? 0) - 1 )){,&nbsp;}
              </div>
              }
            </div>
          </div>
          @if(booking.inboundExisitingFlight || booking.outboundExisitingFlight || booking.arrivalFlight || booking.departureFlight){
          <div>
            <div class="text-0_75-0_75-400 color-formControl">
              {{t('member.bookings.fields.flights')}}:
            </div>
            <div class="flex-column text-0_875-0_875-500">
              @if (booking.inboundExisitingFlight) {
              <div class="flex-start">
                <app-icon
                  size="small"
                  filled="textPrimary"
                  name="arrival">
                </app-icon>
                @if(booking.inboundExisitingFlight.flightNumber){
                #{{booking.inboundExisitingFlight.flightNumber}}
                }
                @if (booking.inboundExisitingFlight.arrivalAirportCode) {
                {{airports[booking.inboundExisitingFlight.arrivalAirportCode] || booking.inboundExisitingFlight.arrivalAirportCode}}
                }
              </div>
              }
              @if (booking.outboundExisitingFlight) {
              <div class="flex-start">
                <app-icon
                  size="small"
                  filled="textPrimary"
                  name="departure">
                </app-icon>
                @if(booking.outboundExisitingFlight.flightNumber){
                #{{booking.outboundExisitingFlight.flightNumber}}
                }
                @if (booking.outboundExisitingFlight.departureAirportCode) {
                {{airports[booking.outboundExisitingFlight.departureAirportCode] || booking.outboundExisitingFlight.departureAirportCode}}
                }
              </div>
              }
              @if (booking.arrivalFlight){
              <div class="flex-start">
                <app-icon
                  size="small"
                  filled="textPrimary"
                  name="arrival">
                </app-icon>
                {{booking.arrivalFlight.departureAirportCode}} => {{booking.arrivalFlight.arrivalAirportCode}}
                {{booking.arrivalFlight.marketingAirlineName}}
                @if(booking.arrivalFlight.flightNumber){
                &nbsp;({{booking.arrivalFlight.flightNumber}})
                }
              </div>
              }
              @if (booking.departureFlight){
              <div class="flex-start">
                <app-icon
                  size="small"
                  filled="textPrimary"
                  name="departure">
                </app-icon>
                {{booking.departureFlight.departureAirportCode}} => {{booking.departureFlight.arrivalAirportCode}}
                {{booking.departureFlight.marketingAirlineName}}
                @if(booking.departureFlight.flightNumber){
                &nbsp;({{booking.departureFlight.flightNumber}})
                }
              </div>
              }
            </div>
          </div>
          }
        </div>
      </div>
    </div>
    }

    @if(history.length){
    <div class="padding text-1_125-1_25-500 margin-top">
      {{t('member.bookings.history')}}:
    </div>
    }
    @for (booking of history; track $index) {
    <div class="padding-horizontal"
      [class.border-left]="!isOpened[$index]"
      [class.border-selected-left]="isOpened[$index]">
      <div class="flex-start gap-quarter cursor-pointer"
        (click)="toggleOpenHistory($index)">
        <app-icon circleSize="middle"
          [circle]="isOpenedHistory[$index] ? 'selected' : 'textInactive'">
          <span class="color-default text-1-1-500">{{isOpenedHistory[$index] ? '-' : '+'}}</span>
        </app-icon>
        <div class="flex-between flex-wrap full-size text-1_125-1_125-500 padding-vertical"
          [class.color-selected]="isOpenedHistory[$index]"
          [class.color-textInactive]="!isOpenedHistory[$index]">
          <div>
            {{
            t('cart.fromTo', {
            from: booking.arrivalDate| appDate: 'type4',
            to: booking.departureDate | appDate: 'type4'
            })
            }}
          </div>
          <div class="flex-between">
            <app-icon
              size="middle"
              filled="textPrimary"
              name="resort">
            </app-icon>
            <div class="color-textPrimary">
              <i>{{resorts[booking.resortCode || ''] || booking.resortCode}}</i>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-between-start gap flex-wrap collapsable"
        [class.collapsed]="!isOpenedHistory[$index]">
        <div class="flex-column gap">
          <div>
            <div class="text-0_75-0_75-400 color-formControl">
              {{t('member.bookings.fields.status')}}:
            </div>
            <div class="text-1-1_5-500">
              {{booking.status}}
              @if(booking.points){
              &nbsp;
              <span [innerHTML]="t('member.bookings.fields.points', {
                value: booking.points })">
              </span>
              }
            </div>
          </div>
          <div>
            <div class="text-0_75-0_75-400 color-formControl">
              {{t('member.bookings.fields.roomNumber')}}:
            </div>
            <div class="text-1-1_5-500">
              {{booking.roomNumber}}
            </div>
          </div>
        </div>
        <div class="flex-column gap">
          <div>
            <div class="text-0_75-0_75-400 color-formControl">
              {{t('member.bookings.fields.arrival')}}:
            </div>
            <div class="flex-between">
              <div class="text-1-1_5-500">
                {{booking.arrivalDate | appDate: 'type3'}}
              </div>
            </div>
          </div>
          <div>
            <div class="text-0_75-0_75-400 color-formControl">
              {{t('member.bookings.fields.departure')}}:
            </div>
            <div class="flex-between">
              <div class="text-1-1_5-500">
                {{booking.departureDate | appDate: 'type3'}}
              </div>
            </div>
          </div>
        </div>
        <div class="flex-column gap">
          <div>
            <div class="text-0_75-0_75-400 color-formControl">
              {{t('member.bookings.fields.resort')}}:
            </div>
            <div class="flex-between">
              <div class="text-1-1_5-500">
                {{resorts[booking.resortCode || ''] || booking.resortCode}}
              </div>
            </div>
          </div>
          <div>
            <div class="text-0_75-0_75-400 color-formControl">
              {{t('member.bookings.fields.room')}}:
            </div>
            <div class="flex-between">
              <div class="text-1-1_5-500">
                {{rooms[booking.resortCode || ''][booking.roomCode || ''] || booking.roomCode}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    }
  </div>
</ng-container>
