import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type BreadCrumbsItem = {
  caption: string;
  action?: () => void
}

@Component({
  selector: 'app-bread-crumbs-v2',
  templateUrl: './bread-crumbs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class BreadCrumbsV2Component {
  @Input() items: BreadCrumbsItem[] = [];
  @Input() section?: string;
  @Input() itemClass = 'link';
}
