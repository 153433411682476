import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-two-columns',
  templateUrl: './two-columns.component.html',
  styleUrls: ['./two-columns.component.scss'],
  standalone: false
})
export class TwoColumnsComponent {
  @Input() rightColumnMaxWidth: 'third' | 'half' = 'third';
  @ContentChild('leftColumn') leftColumn!: TemplateRef<unknown>;
  @ContentChild('rightColumn') rightColumn!: TemplateRef<unknown>;
  @Input() isSticky = true;
  @Input() isReversed = false;
}
