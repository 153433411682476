import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { Color, Size } from '@shared/models/common';

@Component({
  selector: 'app-info-label',
  templateUrl: './info-label.component.html',
  styleUrls: ['./info-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class InfoLabelComponent implements OnChanges {
  @Input() size: Size = 'big';
  @Input() text?: string;
  @Input() type!: Color;
  @Input() textColor?: Color;
  @Input() onCard = false;

  @Input() isUpperCased?: boolean;

  ngOnChanges(): void {
    if (this.isUpperCased === undefined) {
      this.isUpperCased = this.type === 'ratePlanType' || this.type === 'successInfoLabel';
    }
  }
}
