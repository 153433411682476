import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '@shared/services/data.service';

@Pipe({
  name: 'airport',
  standalone: false
})
export class AirportPipe implements PipeTransform {
  constructor(
    private readonly _data: DataService
  ) {
  }

  transform(airportCode: string | undefined, type: 'nameFull' | 'cityFull' | 'nameShort' | 'cityShort' = 'nameFull') {
    const isCity = type === 'cityFull' || type === 'cityShort';
    const isFull = type === 'nameFull' || type === 'cityFull';

    const airport = this._data.values.airports.find(({ code }) => code === airportCode);
    let result = (isCity ? airport?.city : airport?.name) || '';
    if (isFull && airportCode) {
      result += ` (${airportCode})`;
    }

    return result;
  }
}
