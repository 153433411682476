import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Page, pages } from '@shared/models/common';
import { TenantService } from './tenant.service';
import { DOCUMENT } from '@angular/common';

const {
  bypass, order, check,
  results, booking, confirmation,
  search, reservation, verification
} = pages;

const fallbackPage = results;

@Injectable()
export class NavigationService {
  constructor(
    private readonly _router: Router,
    public readonly tenant: TenantService,
    @Inject(DOCUMENT) @Optional() public document: Document,
  ) {
  }

  isOn = {
    bypass: () => this.isPage(bypass),
    order: () => this.isPage(order),
    check: () => this.isPage(check),

    searchV1: () => this.isPage(search) || this.isPage(bypass),
    searchV2: () => this.isPage(results),

    search: () => this.isPage(results) || this.isPage(search) || this.isPage(bypass),

    bookingV1: () => this.isPage(reservation) || this.isPage(order),
    bookingV2: () => this.isPage(booking),

    booking: () => this.isPage(booking) || this.isPage(reservation) || this.isPage(order),

    confirmationV1: () => this.isPage(verification) || this.isPage(check),
    confirmationV2: () => this.isPage(confirmation),

    confirmation: () => this.isPage(confirmation) || this.isPage(verification) || this.isPage(check),
  };

  get isTest() {
    return this.document.location.search.toLowerCase().indexOf('istest=true') > -1;
  }

  get nextPage(): Page {
    const current = this.getCurrentPage();

    const all = {
      [bypass]: order,
      [order]: check,
      [check]: bypass,

      [results]: booking,
      [booking]: confirmation,
      [confirmation]: results,

      [search]: reservation,
      [reservation]: verification,
      [verification]: search
    }

    return current ? all[current] : fallbackPage;
  }

  get prevPage(): Page {
    const current = this.getCurrentPage();

    const all = {
      [bypass]: bypass,
      [order]: bypass,
      [check]: order,

      [results]: results,
      [booking]: results,
      [confirmation]: booking,

      [search]: search,
      [reservation]: search,
      [verification]: reservation
    }

    return current ? all[current] : fallbackPage;
  }

  openNext(...params: string[]) {
    this.open(this.nextPage, ...params);
  }

  openPrev(...params: string[]) {
    this.open(this.prevPage, ...params);
  }

  openSearch() {
    this.open(this.getSearchPage());
  }

  getSearchPage() {
    const currentPage = this.getCurrentPage();
    switch (currentPage) {
      case pages.results:
      case pages.booking:
      case pages.confirmation:
        return pages.results;
      case pages.search:
      case pages.reservation:
      case pages.verification:
        return pages.search;
      case pages.bypass:
      case pages.order:
      case pages.check:
        return pages.bypass;
      default:
        throw new Error('Route search page is not defined: ' + currentPage);
    }
  }

  open(destination: Page, ...params: string[]) {
    this._router.navigate([
      [this.tenant.id, destination, ...params].filter(x => x).join('/')
    ], {
      queryParamsHandling: 'preserve'
    });
  }

  getCurrentPage = (): Page | undefined => {
    const pathParts = this.document.location.pathname.split('/');
    return pathParts.length > 2 ? pathParts[2] as Page : undefined;
  }

  isPage = (page: Page) => page === this.getCurrentPage();
}
