import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ConfirmationService } from '@confirmation/services/confirmation.service';
import { tripProtectionCategory } from '@shared/consts/common';

@Component({
  selector: 'app-addon-summary',
  templateUrl: './addon-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AddonSummaryComponent {
  addOns = (this._confirmation.values.bookingDetails.addOns || [])
    .filter(addOn => addOn.categoryCode !== tripProtectionCategory)
    .map(addon => ({
      name: addon.name,
      totalPrice: (addon.price || 0) * (addon.quantity || 1),
      quantity: addon.quantity,
      ageGroup: addon.ageGroup
        ? `${addon.ageGroup?.agePeriod?.fromYear} - ${addon.ageGroup?.agePeriod?.toYear}`
        : undefined,
      description: addon.description
    }));

  constructor(
    private readonly _confirmation: ConfirmationService
  ) { }
}
