<ng-container *transloco="let t">
  @if (selectedMenuItem && profile){
  <div class="page-container">
    <div class="bc-transparent cursor-pointer header"
      (click)="close()">
    </div>
    <div class="page-content flex-start-start full-size bc-background overflow-hidden-scroll">
      <div class="flex-center-stretch flex-1 w-100 margin-bottom">
        <div class="main-container flex-column flex-1">
          <div class="flex-start padding-vertical padding-left-half gap-quarter"
            #backRow
            (click)="close()">
            <app-icon-button
              name="previous"
              stroked="formControl"
              iconSize="small">
            </app-icon-button>
            <span class="link">{{t('button.back')}}</span>
          </div>
          <div class="flex-between-start gap flex-1 menu-container flex-wrap">
            <div class="menu-column w-100">
              <div class="flex-column first-item">
                <div class="flex-column bc-default padding-horizontal padding-top">
                  <div class="fw-600 three-dots">
                    {{t('sidebar.member.menu.header', { value: profile.firstName})}}
                  </div>
                  <div class="color-formControl fw-500">
                    <p class="three-dots">{{t('sidebar.member.menu.subheader', { value: profile.email})}}</p>
                  </div>
                </div>
                <div class="flex-start gap-quarter bc-default padding-horizontal">
                  <app-info-label
                    [text]="t('member.banner.rewards')"
                    type="memberDefault"
                    textColor="memberText">
                  </app-info-label>
                  <span class="fw-500">
                    {{profile.memberLevel}}
                  </span>
                </div>
                <div class="flex-start gap-quarter color-textInactive fw-500 bc-default padding-horizontal">
                  {{t('member.menu.loyaltyId', { value: profile.memberId })}}
                </div>
                <div class="flex-column gap-quarter padding-top-double bc-default padding">
                  <span class="fw-500">{{t('member.menu.points')}} {{profile.points}}</span>
                  <span class="cursor-pointer"
                    [hidden]="true"
                    (click)="setMenuItem('activity')">
                    {{t('member.menu.viewActivity')}}
                  </span>
                </div>
              </div>
              <ng-container *ngTemplateOutlet="accountText; context:{ classes: 'margin-vertical-double hidden-tablet-max' }"></ng-container>
              <ng-container *ngTemplateOutlet="menu"></ng-container>
            </div>
            <ng-container *ngTemplateOutlet="accountText; context:{ classes: 'margin-vertical-half hidden-tablet-min' }"></ng-container>
            <div class="flex-start-start flex-1 content-column"
              [@memberMenuItemChange]="isAnimating">
              @switch (selectedMenuItem) {
              @case (memberMenuItems.profile) {
              <app-member-profile-v2 (menuItemSelected)="scrollToTop()"></app-member-profile-v2>
              }
              @case(memberMenuItems.bookings){
              <app-member-bookings-v2></app-member-bookings-v2>
              }
              @case(memberMenuItems.rewards){
              <app-member-rewards-v2></app-member-rewards-v2>
              }
              @case(memberMenuItems.help){
              <app-member-support-v2></app-member-support-v2>
              }
              @case (otherPageItems.activity) {
              <app-member-activity-v2></app-member-activity-v2>
              }
              @default {
              <div class="flex-start-start flex-100">Not Implemented (Yet)</div>
              }
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  } @else {
  <app-sidebar-layout
    scope="member"
    [headerValue]="profile?.firstName"
    [subheaderValue]="profile?.email"
    [isFooter]="!!profile"
    [subScope]="sidebarSubScope">
    <ng-template #content>
      <div class="member-layout overflow-hidden">
        @if (profile) {
        <div class="flex-start gap-quarter">
          <app-info-label
            [text]="t('member.banner.rewards')"
            type="memberDefault"
            textColor="memberText">
          </app-info-label>
          <span class="fw-500">
            {{profile.memberLevel}}
          </span>
        </div>
        <div class="flex-start gap-quarter color-textInactive fw-500">
          {{t('member.menu.loyaltyId')}} {{profile.memberId}}
        </div>
        <div class="flex-column gap-quarter margin-top-double margin-bottom-double">
          <span class="fw-500">{{t('member.menu.points')}} {{profile.points}}</span>
          <span class="cursor-pointer"
            [hidden]="true"
            (click)="setMenuItem(otherPageItems.activity)">
            {{t('member.menu.viewActivity')}}
          </span>
        </div>
        <ng-container *ngTemplateOutlet="menu"></ng-container>
        }
        @else {
        <app-member-start-v2></app-member-start-v2>
        }
      </div>
    </ng-template>
    <ng-template #footer>
      @if(profile){
      <div class="flex-between gap">
        <button mat-raised-button
          (click)="signOut()">
          {{t('manageBooking.account.signOut')}}
        </button>
        @if(!isOnSearchPage){
        <div class="text-0_875-0_875-500 color-formControl text-end">{{t('manageBooking.account.signOutRedirect')}}</div>
        }
      </div>
      }
    </ng-template>
  </app-sidebar-layout>
  }

  <ng-template #menu>
    <div class="bc-default menu-items"
      [class.menu-opened]="!!selectedMenuItem"
      [class.padding]="!!selectedMenuItem">
      @for (item of memberMenu; track $index) {
      <div class="flex-start gap-quarter cursor-pointer"
        (click)="setMenuItem(item)">
        <app-icon
          [size]="iconSize"
          [name]="item"
          [stroked]="item === selectedMenuItem ? 'selected' : iconColor">
        </app-icon>
        <span class="fw-500 menu-caption"
          [class.is-selected]="item === selectedMenuItem"
          [class.color-selected]="item === selectedMenuItem">
          {{t('member.menu.' + item)}}
        </span>
      </div>
      }
    </div>
  </ng-template>
  <ng-template #accountText
    let-classes="classes">
    <div [ngClass]="['flex-start margin-horizontal', classes]">
      {{ t('member.accountText') }}
    </div>
  </ng-template>
</ng-container>