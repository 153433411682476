import { Component, HostBinding } from '@angular/core';
import { BookingEngineHtmlContent } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';
import { htmlContentCodes } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { HtmlContentService } from '@shared/services/html-content.service';

@Component({
  selector: 'app-notification-warnings',
  templateUrl: './notification-warnings.component.html',
  standalone: false
})
export class NotificationWarningsComponent extends ComponentBase {
  constructor(
    services: ComponentBaseService,
    htmlContent: HtmlContentService
  ) {
    super(services);

    this.pushSub(htmlContent.get({
      skipFullLoad: true,
      code: htmlContentCodes.topNotification,
      onLoaded: warnings => this.warnings = warnings
    }));
  }

  @HostBinding('class.hide-if-empty') hideIfEmpty = true;
  warnings?: BookingEngineHtmlContent[];
}
