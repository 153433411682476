import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { DataService } from '@shared/services/data.service';
import { ConfirmationService } from '@confirmation/services/confirmation.service';
import { HtmlContentService } from '@shared/services/html-content.service';
import { TrackingService } from '@shared/services/tracking.service';
import { htmlContentCodes } from '@shared/models/common';
import { BookingEngineHtmlContent, NullableDatePeriod, Resort } from '@shared/api/be-api.generated';

@Component({
  selector: 'app-confirmation-layout',
  templateUrl: './confirmation-layout.component.html',
  standalone: false
})
export class ConfirmationLayoutComponent extends ComponentBase {
  bookingId?: string;
  activeResortLogo?: string;
  activeResortCaption?: string;
  activeResortPhone?: string;
  safetyProtocols?: BookingEngineHtmlContent;
  isInitialized = false;
  isBookingCancelled = false;

  constructor(
    data: DataService,
    confirmation: ConfirmationService,
    services: ComponentBaseService,
    route: ActivatedRoute,
    htmlContent: HtmlContentService,
    tracking: TrackingService
  ) {
    super(services);

    this.pushSub(
      route.paramMap.subscribe(value => {
        const bookingId = value.get('bookingId') || undefined;
        if (bookingId && bookingId !== confirmation.bookingId) {
          confirmation.bookingId = bookingId;
          confirmation.isInitialized = false;
          confirmation.initialize();
        }
      }),
      confirmation.setWhenInitialized(response => {
        if (response?.bookingDetails) {
          const { status, bookingNumber, accommodation, arrivalDate: start, departureDate: finish, pricing } = response.bookingDetails;

          this.isBookingCancelled = status === 'Cancelled';

          this.bookingId = bookingNumber;
          const { logo, caption, phone, resortId = '' } = data.values.resorts
            .find(resort => resort.resortId === accommodation?.resortId) || new Resort();

          this.activeResortLogo = logo?.mainUrl;
          this.activeResortCaption = caption;
          this.activeResortPhone = phone;

          this.pushSub(htmlContent.get({
            code: htmlContentCodes.safetyProtocols,
            onLoaded: result => this.safetyProtocols = result.find(() => true),
            filter: {
              ratePlanTypeIds: [accommodation?.ratePlanTypeId || ''],
              specialOfferIds: pricing?.specialOffers?.map(item => item.specialOfferId || ''),
              resortsIds: [resortId],
              travelPeriod: new NullableDatePeriod({ start, finish }),
            }
          }));

          tracking.purchase(response.bookingDetails)
        }

        this.isInitialized = true;
      })
    )
  }
}
