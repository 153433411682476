<div class="page-container">
  <div class="page-content">
    <app-header-v2></app-header-v2>
    @if (membershipEnabled) {
    <div class="flex height-less">
      <div class="main-container">
        <app-sign-in-banner-v2></app-sign-in-banner-v2>
      </div>
    </div>
    }
    <app-search-bar-v2></app-search-bar-v2>
    <app-search-stepper-v2></app-search-stepper-v2>
    <!-- <app-notification-warnings class="page-section"></app-notification-warnings> -->
    <!--<app-notification-signup class="page-section"></app-notification-signup>-->
    <!-- <app-room-viewer></app-room-viewer>
    <app-flight-select></app-flight-select>
    <app-airport-transfer-select-options></app-airport-transfer-select-options>
    <app-notification-amenities></app-notification-amenities>
    <app-addon-select></app-addon-select>
    <app-cart-summary></app-cart-summary> -->
  </div>
  <app-footer></app-footer>
  <app-fixed-buttons></app-fixed-buttons>

  <ng-container *transloco="let t">
    <app-modal-panel-v2
      [isActive]="!!minimumStay"
      positionHorizontal="center"
      positionVertical="center"
      overlayColor="modalBackground">
      <ng-template #header>
        <div class="text-1_75-1_75-600 color-required padding-bottom">
          {{t('unavailability.minStay.header')}}
        </div>
      </ng-template>
      <ng-template #content>
        <div class="text-1-1-500"
          [innerHtml]="t('unavailability.minStay.message', { current: nights, required: minimumStay })">
        </div>
      </ng-template>
    </app-modal-panel-v2>
    <app-modal-panel-v2
      [isActive]="!!initialArrivalChanged"
      positionHorizontal="center"
      positionVertical="center"
      [maxWidth]="300"
      background="default">
      <ng-template #content>
        <div class="text-1_5-2-600">
          {{t('unavailability.initialArrivalChanged.header')}}
        </div>
        <div class="text-1-1-500 color-textPrimary"
          [innerHtml]="t('unavailability.initialArrivalChanged.content', { value: resortPhone })">
        </div>
      </ng-template>
    </app-modal-panel-v2>
  </ng-container>
</div>