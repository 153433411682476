import { Component } from '@angular/core';
import { MemberService } from '@member/member.service';
import { ComponentBase } from '@shared/base/component.base';
import { UnavailabilityReasonCode } from '@shared/models/common';
import { BlockOverlayService } from '@shared/services/block-overlay.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { ComponentsCommunicationService } from '@shared/services/components-communication.service';
import { PackageService } from '@shared/services/package.service';
import { SearchService } from '@shared/services/search/search.service';
import { TravelAgentService } from '@shared/services/travel-agent.service';

@Component({
  selector: 'app-search-layout-v2',
  templateUrl: './search-layout.component.html',
  styleUrls: ['./search-layout.component.scss'],
  standalone: false
})
export class SearchLayoutV2Component extends ComponentBase {
  constructor(
    comm: ComponentsCommunicationService,
    blockOverlay: BlockOverlayService,
    services: ComponentBaseService,
    _package: PackageService,
    search: SearchService,
    travelAgent: TravelAgentService,
    member: MemberService
  ) {
    super(services);

    this.pushSub(
      comm.flightsEnabled.subscribe(result => this.isRequestedFlights = result.isEnabled),
      search.setWhenContextChanged(() => this.isRequestedFlights = false),
      member.onMemberChanged(() => {
        if (member.profile) {
          travelAgent.travelAgentDetails = undefined;
        }
      }),
      _package.setWhenDataChanged(({ roomTypePackages, resort }) => {
        this.nights = _package.periodNights;

        switch (roomTypePackages?.unavailabilityReason?.code as UnavailabilityReasonCode) {
          case 'MinStayPolicy':
            this.minimumStay = roomTypePackages?.unavailabilityReason?.value;
            break;
          default:
            this.minimumStay = undefined;
            break;
        }

        if (this.initialArrivalChanged === undefined && resort?.phone) {
          this.initialArrivalChanged = search.initialArrivalChanged;
          this.resortPhone = resort?.phone;
        }
      })
    );

    blockOverlay.isOpened && blockOverlay.close();
    if (window && window.location.href.indexOf('localhost')) {
      this.membershipEnabled = false;
    } else {
      this.membershipEnabled = member.membershipEnabled;
    }
  }

  isRequestedFlights = false;
  minimumStay?: string;
  nights?: number;
  initialArrivalChanged?: boolean; // need to skip showing same message a few times
  resortPhone?: string;
  membershipEnabled?: boolean;
}
