<ng-container *transloco="let t">
  @if(transfers.length || (!isFlights && airports.length)){
  <div class="airport-transfer-select">
    <div class="content-container">
      <div class="flex-column padding-less">
        <div class="section-header"
          [hidden]="hideHeader">
          <div class="section-header-text">
            <app-anchor type="section" [index]="sectionNumber"></app-anchor>
            3. {{t('airportTransfer.header')}}
          </div>
          <app-section-toggler #toggler
            [isOnlyForTablet]="false">
          </app-section-toggler>
        </div>
        <div class="collapsable padding-horizontal padding-bottom"
          [class.collapsed]="toggler.isHidden">
          <div class="section-subheader"
            hidden>
            <div class="section-subheader-text">
              {{t('airportTransfer.subheader')}}
            </div>
          </div>
          @if(!isFlights && airports.length){
          <div class="flex-start flex-wrap select-container">
            <div class="text-1-2-400 no-wrap margin-right">
              {{t('airport.select')}}:
            </div>
            <mat-form-field class="airport-select">
              <mat-select
                #select
                [disabled]="!isOnSearchPage"
                [compareWith]="compareAirport"
                [value]="selectedAirport"
                (selectionChange)="airportChanged($event.value)">
                <mat-select-trigger>
                  <div class="flex-start">
                    <app-icon
                      size="small"
                      stroked="textPrimary"
                      filled="transparent"
                      [rotate]="90"
                      name="airplane">
                    </app-icon>
                    <div class="three-dots">{{select.value.displayText}}</div>
                  </div>
                </mat-select-trigger>
                <mat-option
                  *ngFor="let airport of airports; trackBy: 'code' | createTrackBy"
                  [value]="airport">
                  {{airport.displayText}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          }
          @if (!transfers.length){
          <div class="flex padding text-0_75-1-500">
            {{t('airport.notAvailable')}}
          </div>
          }
          @if(isBrowser && transfers.length){
          <cdk-virtual-scroll-viewport
            [appendOnly]="false"
            class="card-options-container"
            orientation="horizontal"
            #options
            [itemSize]="itemSize">
            <ng-container
              *cdkVirtualFor="let selectConfig of selectConfigs; trackBy: 'id' | createTrackBy;">
              <app-select-option-v2
                #option
                [config]="selectConfig"
                [sidebarContext]="{ template, width: 'default', position: 'end' }">
                <ng-template #footer>
                  <app-pricing-details-v2
                    [config]="pricingConfigs[selectConfig.id]"
                    (selectClick)="option.openDetails()">
                    <app-airport-transfer-pricing
                      [current]="sortedTransfers[selectConfig.id]">
                    </app-airport-transfer-pricing>
                  </app-pricing-details-v2>
                </ng-template>
              </app-select-option-v2>
              <ng-template #template>
                <app-airport-transfer-details
                  [pricingConfig]="pricingConfigs[selectConfig.id]"
                  [current]="sortedTransfers[selectConfig.id]"
                  [images]="selectConfig.images">
                </app-airport-transfer-details>
              </ng-template>
            </ng-container>
          </cdk-virtual-scroll-viewport>
          }
        </div>
      </div>
    </div>
  </div>
  }

</ng-container>