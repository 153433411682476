import { Component, Input } from '@angular/core';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';

@Component({
  selector: 'app-notification-signup',
  templateUrl: './notification-signup.component.html',
  styleUrls: ['./notification-signup.component.scss'],
  standalone: false
})
export class NotificationSignupComponent {
  @Input() isOnlyMobileView = false;

  constructor(
    private readonly _sidebar: SidebarService,
  ) { }

  openAccount() {
    this._sidebar.openAccount();
  }
}
