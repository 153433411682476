import { EventEmitter, Injectable } from '@angular/core';
import { map, of, tap } from 'rxjs';
import { concatLast } from '@shared/common';

import { TranslocoService } from '@jsverse/transloco';
import { MemberAuthService } from '@member/member.auth.service';
import { TenantService } from '@shared/services/tenant.service';

import { MemberInfo } from './member.info';
import { topCountriesTwoLetterCodes as topCountries } from '@shared/consts/common';
import { ApiException, BookingEngineClient, MemberSupportEmail } from '@shared/api/be-api.generated';
import { MembershipClient, MembershipRewardsResponse } from '@shared/api/be-api.generated';
import { MemberLoginRequest, MembershipProfile, MembershipRegistration, TravelCompanion } from '@shared/api/be-api.generated';

@Injectable()
export class MemberService {
  constructor(
    private readonly _transloco: TranslocoService,
    private readonly _apiClient: BookingEngineClient,
    private readonly _memberClient: MembershipClient,
    private readonly _tenant: TenantService,
    private readonly _auth: MemberAuthService
  ) {
    _auth.authChanged.subscribe(profile => {
      this._setMemberProfile(profile);
      this._memberInfo.authChanged();
    });
    _auth.profile && this._setMemberProfile(this._auth.profile);
  }

  memberChanged = new EventEmitter<MembershipProfile>();
  profile?: MembershipProfile;
  private readonly _memberInfo = new MemberInfo();

  private get memberId() {
    return this.profile?.memberId || '';
  }

  private get membershipProvider() {
    return this._tenant.membershipProvider;
  }

  public get membershipEnabled() {
    return this._tenant.membershipEnabled;
  }

  //#region Set
  onMemberChanged(onChanged: (data: MemberService) => void) {
    return this.memberChanged.subscribe(() => onChanged(this))
  }

  private _setMemberProfile(profile?: MembershipProfile) {
    this.profile = profile;
    this.memberChanged.emit(this.profile);
  }
  //#endregion

  //#region Get
  getCountries() {
    return this._memberInfo.countries
      ? of(this._memberInfo.countries)
      : this._apiClient.getCountries(this._tenant.id).pipe(map(result => ([
        ...result.filter(item => topCountries.includes(item.twoLetterCode)),
        ...result.filter(item => !topCountries.includes(item.twoLetterCode))
      ])), tap(result => this._memberInfo.countries = result));
  }

  getMembershipError(error: ApiException, conditions: Record<string, (response: string) => boolean> = {}) {
    let errorMessageKey = 'member.apiError.default';

    if (error?.status === 401) {
      errorMessageKey = 'member.apiError.unauthorized';
    }
    else if (!error?.response) {
      //skip others checks
    }
    else if (error.response.startsWith('<!DOCTYPE html>')) {
      errorMessageKey = 'member.apiError.maintaince';
    }
    else {
      const key = Object.keys(conditions)
        .find(key => conditions[key](error.response))

      if (key) {
        errorMessageKey = key;
      } else {
        return error.response;
      }
    }

    return this._transloco.translate(errorMessageKey);
  }

  getMembershipLevels() {
    const { levels, benefits } = this._memberInfo;

    return levels && benefits
      ? of(new MembershipRewardsResponse({ levels, benefits }))
      : this._memberClient.getMembershipLevels(this._tenant.id, this.membershipProvider).pipe(tap(result => {
        this._memberInfo.levels = result.levels;
        this._memberInfo.benefits = result.benefits;
      }));
  }

  getBookings() {
    return this._memberInfo.bookings
      ? of(this._memberInfo.bookings)
      : this._memberClient.getBookings(this._tenant.id)
        .pipe(tap(result => this._memberInfo.bookings = result));
  }
  //#endregion

  //#region Account
  register(email: string, password: string, onError: (ex: ApiException) => void) {
    return this._memberClient.register(this._tenant.id, new MembershipRegistration({ email, password }))
      .pipe(tap(({ profile, token, expireIn, error }) => {
        if (error) {
          onError(new ApiException(error, 400, error, {}, error));
        } else {
          return this._auth.signIn(profile, token, expireIn);
        }
      })).subscribe({ error: onError });
  }

  signIn(email: string, password: string, onError: (ex: ApiException) => void) {
    return this._memberClient.login(this._tenant.id, new MemberLoginRequest({ password, email }))
      .pipe(tap(({ profile, token, expireIn, error }) => {
        if (error) {
          onError(new ApiException(error, 400, error, {}, error));
        } else {
          return this._auth.signIn(profile, token, expireIn);
        }
      })).subscribe({ error: onError });
  }

  signOut() {
    this._auth.signOut();
  }

  update(profile: MembershipProfile) {
    return this._memberClient.update(this._tenant.id, profile)
      .pipe(tap(() => this._setMemberProfile(profile)));
  }

  forgotPassword(email: string) {
    return this._memberClient.forgotPassword(this._tenant.id, new MemberLoginRequest({ email }));
  }
  //#endregion

  //#region Additional Travellers
  loadTravelers(force = false) {
    return (!force && this._memberInfo.travellers)
      ? of(this._memberInfo.travellers)
      : this._memberClient.getTravelCompanions(this._tenant.id, this.memberId)
        .pipe(tap(result => this._memberInfo.travellers = result));
  }

  addTraveller(traveller: TravelCompanion) {
    // there is no id provided in response, need to reload all
    return concatLast(
      this._memberClient.addTravelCompanion(this._tenant.id, this.memberId, traveller),
      this.loadTravelers(true)
    ).pipe(map(() => void 0));
  }

  updateTraveller(traveller: TravelCompanion) {
    const { travellers } = this._memberInfo;

    return this._memberClient.updateTravelCompanion(this._tenant.id, traveller).pipe(tap(() => {
      if (travellers) {
        const index = travellers.findIndex(item => item.id === traveller.id);
        if (index > -1 && this._memberInfo.travellers) {
          travellers[index] = traveller;
        }
        return;
      }

      throw new Error('Should not happen. Updated companion not found.');
    }));
  }

  saveTraveller(traveller: TravelCompanion) {
    return traveller.id ? this.updateTraveller(traveller) : this.addTraveller(traveller);
  }

  deleteTraveller(traveller: TravelCompanion) {
    const { travellers } = this._memberInfo;
    if (!travellers) {
      throw new Error('Should not happen. Travellers not initialized.');
    }

    return this._memberClient.deleteTravelCompanion(this._tenant.id, this.memberId, traveller.id ?? 0)
      .pipe(tap(() => this._memberInfo.travellers = travellers.filter(item => item.id !== traveller.id)));
  }
  //#endregion

  //#region Other
  sendSupportEmail(subject: string, body: string) {
    return this._memberClient.sendSupportEmail(this._tenant.id,
      new MemberSupportEmail({ subject, body, membershipProvider: this.membershipProvider }));
  }


  //#endregion
}
