import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
  name: 'isFormControlRequired',
  standalone: false
})
export class IsFormControlRequiredPipe implements PipeTransform {
  public transform(control?: AbstractControl | null): boolean {
    return control?.validator && (control.validator({} as AbstractControl) || {})['required'];
  }
}
