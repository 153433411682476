import { EventEmitter, Injectable } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay } from 'rxjs';
import { DateTime } from 'luxon';
import { assign } from 'xstate';

import { defaultDaysSearchPeriod, defaultDaysSearchStart, defaultStopsCount, maximumNightsToProcess, minimumDaysFromNow } from '@shared/consts/common';

import searchSM from './search.sm';
import { StateMachineBootstrapperService } from '../state/state-machine-bootstrapper.service';

import { Airport, Room, Resort, RoomType, RoomTypePackages, RatePlanType } from '@shared/api/be-api.generated';
import { BookingEngineClient, ApiException, SearchContext } from '@shared/api/be-api.generated';
import { IFlightSearch, FlightSearch, PackageRequest } from '@shared/api/be-api.generated';

import { DataService } from '@shared/services/data.service';
import { QueryParamsService } from '../query-params.service';
import { getDefaultRoom } from '@shared/common';
import { AirportsBuilderInput } from '@shared/models/common';
import { TenantService } from '../tenant.service';
import { DefaultSearchData } from './search-context.types';
import { MemberAuthService } from '@member/member.auth.service';

@Injectable({ providedIn: 'root' })
export class SearchService {
  readonly _searchMachine = this._smFactory.bootstrapMachine(searchSM.withConfig({
    actions: {
      initialize: assign((_) => {
        const { resorts, airports, ratePlanTypes } = this._data.values;
        const { request } = this._queryParams.value;
        const memberId = this._auth.profile?.memberId;

        const flightSearch = request?.flightSearch;
        const promoCode = request.promoCode || undefined;

        const resort = resorts.find(resort => !request?.resortId || resort.resortId === request.resortId)
          || resorts.find(() => true); // or first

        const isValidFlightCodes = airports.some(a => a.code === flightSearch?.fromAirportCode)
          && (!flightSearch?.toAirportCode || resort?.airports?.some(a => a.code === flightSearch?.toAirportCode))
          && flightSearch?.fromAirportCode !== flightSearch?.toAirportCode;

        // first try to use rate plan type from query string
        const ratePlanType = ratePlanTypes.find(rpt => rpt.ratePlanTypeId === request?.ratePlanTypeId)
          // then, if valid flight codes - use rate plan type with flightsIncluded
          || ratePlanTypes.find(rpt => isValidFlightCodes && rpt.flightsIncluded)
          // then, without flights if no flights
          || ratePlanTypes.find(rpt => !isValidFlightCodes && !rpt.flightsIncluded)
          // then at least something
          || ratePlanTypes.find(() => true);

        const {
          arrivalAirport,
          departureAirport
        } = this._getAirports({ ratePlanType, flightSearch, resort, airports });

        const minimumFrom = DateTime.now().startOf('day').plus({ days: minimumDaysFromNow });
        this.initialArrivalChanged = !!request?.fromDate && request.fromDate < minimumFrom;
        if (this.initialArrivalChanged) {
          request.fromDate = minimumFrom;
        }

        const fromDate = request?.fromDate || DateTime.now().plus({ days: defaultDaysSearchStart });
        const daysDifference = request?.toDate?.diff(fromDate, 'days').days || 0;

        const toDate = request?.toDate && daysDifference > 0 && daysDifference <= maximumNightsToProcess
          ? request.toDate
          : fromDate.plus({ days: defaultDaysSearchPeriod });

        const rooms = [getDefaultRoom(0, request?.adults, request?.children)];

        // if (flightSearch) {
        //   flightSearch.cabinTypes = ['S', 'Y'];
        // }


        return {
          rooms, resort, ratePlanType, fromDate, toDate,
          arrivalAirport, departureAirport, flightSearch,
          promoCode, memberId
        };
      }),

      switchRatePlanType: assign(({ ratePlanType }, _) => ({
        ratePlanType: this._data.values.ratePlanTypes
          .find(rpt => !!rpt.flightsIncluded !== !!ratePlanType?.flightsIncluded) || ratePlanType
      })),
      setRatePlanType: assign(({ arrivalAirport, departureAirport }, { ratePlanTypeId }) => {
        const ratePlanType = this._data.values.ratePlanTypes.find(rpt => rpt.ratePlanTypeId === ratePlanTypeId);

        return {
          ratePlanType,
          arrivalAirport: ratePlanType?.flightsIncluded ? arrivalAirport : undefined,
          departureAirport: ratePlanType?.flightsIncluded ? departureAirport : undefined
        };
      }),

      setAirports: assign(({ flightSearch: currentFlightSearch, ratePlanType: currentRatePlanType },
        { arrivalAirport, departureAirport, ratePlanType: newRatePlanType }) => {
        const isFlight = !!arrivalAirport && !!departureAirport;

        const ratePlanType = newRatePlanType // use new
          || (currentRatePlanType?.flightsIncluded === isFlight // if is not changed flight search criteria
            ? currentRatePlanType // then keep it
            : isFlight ? this._data.flightRatePlanType : this._data.noFlightRatePlanType); // or fallback

        const flightSearch = new FlightSearch({
          ...currentFlightSearch,
          fromAirportCode: departureAirport?.code,
          toAirportCode: arrivalAirport?.code,
        });

        return ({ arrivalAirport, departureAirport, flightSearch, ratePlanType });
      }),
      swapAirports: assign(({ arrivalAirport: departureAirport, departureAirport: arrivalAirport }, _) =>
        ({ arrivalAirport, departureAirport })),

      setDateRange: assign((_, { fromDate, toDate }) => ({ fromDate, toDate })),
      applyHotelFilter: assign(({ ratePlanType, flightSearch }, { rooms, resort }) => {
        const { airports } = this._data.values;
        const {
          arrivalAirport,
          departureAirport
        } = this._getAirports({ ratePlanType, flightSearch, airports, resort });

        flightSearch = new FlightSearch({
          ...flightSearch,
          fromAirportCode: departureAirport?.code,
          toAirportCode: arrivalAirport?.code
        });

        return {
          rooms,
          resort,
          arrivalAirport,
          departureAirport,
          flightSearch
        };
      }),

      applySearch: assign((_, searchData) => ({ ...searchData })),

      patchFlightSearch: assign(({ flightSearch }, { flightSearch: input }) =>
        ({ flightSearch: new FlightSearch({ ...flightSearch, ...input }) })),

      setPromocode: assign((_, { promoCode }) => ({ promoCode })),

      loadMore: assign((_, { roomType }) => ({ roomType })),

      setSearchResult: assign((_, { flights, roomTypes: roomTypesPackages, totalFlights }) => ({ roomTypesPackages, flights, totalFlights })),
      appendSearchResult: assign((_, { flights, roomTypes: roomTypesPackages }) => ({ roomTypesPackages, flights, roomType: undefined })),

      loadPackagesPrices: (_, { exceptRoomTypeId }) => {
        this._apiClient.loadPackagePrices(this._tenant.id, this._getPackageRequest(exceptRoomTypeId))
          .subscribe(({ roomTypes }) => this._searchMachine.send({ type: 'SET_PACKAGES_PRICES_RESULT', roomTypes }))
      },
      setPackagesPricesResult: assign(({ roomTypesPackages, resort }, { roomTypes: roomTypesPackagesResponse }) => ({
        roomTypesPackages: resort?.roomTypes?.map(roomType => {
          const result: RoomTypePackages = roomTypesPackagesResponse?.find(x => x.roomTypeId === roomType.roomTypeId)
            || roomTypesPackages?.find(x => x.roomTypeId === roomType.roomTypeId)
            || new RoomTypePackages({ specialOffers: [], packages: [] });

          result.resortCaption = resort.caption;
          result.roomTypeCaption = roomType.caption;

          return result;
        })
      })),

      setSearchError: (_, { data }) => this.searchError.emit(data as ApiException),
      entryIdle: () => {
        //#region restore load more/prices #1
        if (!this.isFirstSearchDone) {
          this.initialFlightId = this._queryParams.value.flightId;
          this.isFirstSearchDone = true;
        }
        //#endregion
      },
      setMember: assign((_, { memberId }) => ({ memberId })),
    },
    services: {
      runSearch: (_, { type: eventType }) => this._createSearchRequest().pipe(map(response => {
        const type = eventType === 'LOAD_MORE' ? 'APPEND_SEARCH_RESULT' : 'SET_SEARCH_RESULT';
        return { type, ...response };
      }))
    },
  }));

  searchError = new EventEmitter<ApiException>();
  isFirstSearchDone = false;
  initialFlightId?: string;

  get isFlightSearchAllowed() {
    return !!this.state.context.ratePlanType?.flightsIncluded;
  }

  get state() {
    return this._searchMachine.state;
  }

  state$ = this._searchMachine.state$;
  context$ = this._searchMachine.state$.pipe(
    filter(state => state.matches('idle')),
    map(state => state.context),
    distinctUntilChanged()
  );

  loading$ = this.state$.pipe(shareReplay(1), debounceTime(750), map(() => this.state.matches('loading')));
  initialArrivalChanged = false;

  constructor(
    private readonly _smFactory: StateMachineBootstrapperService,
    private readonly _tenant: TenantService,
    private readonly _apiClient: BookingEngineClient,
    private readonly _data: DataService,
    private readonly _queryParams: QueryParamsService,
    private readonly _auth: MemberAuthService
  ) {
    this._queryParams.routeChanged.subscribe(() => this.state.matches('unInitialized') && this.initialize());
  }

  get isInitialized() {
    return !this._searchMachine.state.matches('unInitialized');
  }


  runInititalization() {
    !this.isInitialized && this.initialize();
  }

  initialize = () => this._searchMachine.send({ type: 'INITIALIZE' });
  loadMore = (roomType: RoomType) => this._searchMachine.send({ type: 'LOAD_MORE', roomType });
  setAirports = (departureAirport?: Airport, arrivalAirport?: Airport, ratePlanType?: RatePlanType) =>
    this._searchMachine.send({ type: 'SET_AIRPORTS', departureAirport, arrivalAirport, ratePlanType });
  swapAirports = () => this._searchMachine.send({ type: 'SWAP_AIRPORTS' });
  setDateRange = (fromDate: DateTime, toDate: DateTime) => this._searchMachine.send({ type: 'SET_DATE_RANGE', fromDate, toDate });
  patchFlight = (flightSearch: Partial<IFlightSearch>) => this._searchMachine.send({ type: 'PATCH_FLIGHT_SEARCH', flightSearch });
  applyHotelFilter = (resort: Resort, rooms: Room[]) => this._searchMachine.send({ type: 'APPLY_HOTEL_FILTER', resort, rooms });
  loadPackagesPrices = (exceptRoomTypeId?: string) => this._searchMachine.send({ type: 'LOAD_PACKAGES_PRICES', exceptRoomTypeId });
  setPromoCode = (promoCode?: string) => this._searchMachine.send({ type: 'SET_PROMOCODE', promoCode });
  switchRatePlanType = () => this._searchMachine.send({ type: 'SWITCH_RATE_PLAN_TYPE' });
  setRatePlanType = (ratePlanTypeId?: string) => this._searchMachine.send({ type: 'SET_RATE_PLAN_TYPE', ratePlanTypeId });
  applySearch = (data: DefaultSearchData) => this._searchMachine.send({ type: 'APPLY_SEARCH', ...data });
  setMember = (memberId?: string) => this._searchMachine.send({ type: 'SET_MEMBER', memberId });

  getTotalGuests() {
    return this.getTotalAdults() + this.getTotalChildren();
  }

  getTotalAdults() {
    return (this.state.context.rooms || [])
      .map(r => r.adults || 0)
      .reduce((a1, a2) => a1 + a2, 0) ?? 0;
  }

  getTotalChildren() {
    return this.getChildren().length;
  }

  getChildren() {
    return (this.state.context.rooms || [])
      .map(r => r.children || [])
      .reduce((c1, c2) => [...c1, ...c2], []);
  }

  private _createSearchRequest() {
    const searchRequest = this._getPackageRequest();
    const request = new PackageRequest(searchRequest);
    delete request.memberId;
    delete request.membershipProvider;
    delete request.memberData?.memberLevel;
    delete request.memberData?.memberLevelOrderNumber;

    this._queryParams.patchQueryParams({
      request,
      flightId: this.isFlightSearchAllowed
        ? this._queryParams.value.flightId
        : undefined // we don't need flight Id if it's not search request
    });
    return this._apiClient.getPackages(this._tenant.id, searchRequest);
  }

  private _getPackageRequest(exceptRoomTypeId?: string): PackageRequest {
    const {
      ratePlanType, roomType, resort,
      promoCode, toDate, fromDate,
      memberId,
      flightSearch: currentFlightSearch,
      arrivalAirport: currentArrivalAirport,
      departureAirport: currentDepartureAirport
    } = this.state.context;

    const { membershipProvider } = this._tenant;

    const ratePlanTypeId = ratePlanType?.ratePlanTypeId;
    const resortId = resort?.resortId;
    const roomTypeId = roomType?.roomTypeId;
    const adults = this.getTotalAdults();
    const children = this.getChildren();

    let flightSearch = this.isFlightSearchAllowed
      ? new FlightSearch({
        ...currentFlightSearch,
        fromAirportCode: currentDepartureAirport?.code,
        toAirportCode: currentArrivalAirport?.code
      })
      : undefined;

    const { airports } = this._data.values;
    const { arrivalAirport, departureAirport } = this._getAirports({ ratePlanType, flightSearch, resort, airports });

    flightSearch = this.isFlightSearchAllowed
      ? new FlightSearch({
        ...flightSearch,
        fromAirportCode: departureAirport?.code,
        toAirportCode: arrivalAirport?.code
      })
      : undefined;

    if (flightSearch && flightSearch?.stops === undefined) {
      flightSearch.stops = defaultStopsCount;
    }

    return new PackageRequest({
      ratePlanTypeId, resortId, roomTypeId, exceptRoomTypeId,
      toDate, fromDate, promoCode, flightSearch,
      adults, children, memberId, membershipProvider
    });
  }

  public setWhenContextChanged(onContextChanged: (data: SearchContext) => void) {
    // probably I don't need it
    // if (this.isInitialized) {
    //   onContextChanged(this.state.context);
    // }
    return this.context$.subscribe(result => this.isInitialized && onContextChanged(result));
  }

  public setWhenStateChanged(onStateChanged: (data: SearchContext) => void) {
    if (this.isInitialized) {
      onStateChanged(this.state.context);
    }

    return this.state$.subscribe(result => this.isInitialized && onStateChanged(result.context));
  }

  private _getAirports({ ratePlanType, flightSearch, resort, airports }: AirportsBuilderInput) {
    const arrivalAirport = ratePlanType?.flightsIncluded
      // if flights allowed - show from search or first
      ? resort?.airports?.find(a => a.code === flightSearch?.toAirportCode)
      || resort?.airports?.find(() => !flightSearch?.toAirportCode)
      || resort?.airports?.find(() => true) // at least something if query not match
      : undefined;

    const departureAirport = ratePlanType?.flightsIncluded
      ? airports?.find(a => a.code === flightSearch?.fromAirportCode && a.code !== arrivalAirport?.code)
      || airports?.find(a => !flightSearch?.fromAirportCode && a.code !== arrivalAirport?.code)
      : undefined;

    return {
      arrivalAirport,
      departureAirport
    };
  }
}
