import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'createTrackBy',
  standalone: false
})
export class CreateTrackByPipe implements PipeTransform {
  transform<T>(prop: keyof T) {
    return (_index: number, data: T) => data[prop];
  }
}
