import { Component, ChangeDetectionStrategy } from '@angular/core';
import { DataService } from '@shared/services/data.service';
import { ConfirmationService } from '@confirmation/services/confirmation.service';
import { FlightSummary, JourneySummary } from './flight-summary.common';

@Component({
  selector: 'app-flight-summary',
  templateUrl: './flight-summary.component.html',
  styleUrls: ['./flight-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FlightSummaryComponent {
  booking = this._confirmation.values.bookingDetails;

  flightsToBuy = this.booking?.flights?.flightsToBuy;
  existingFlights = this.booking?.flights?.existingFlights;

  inboundFlight = new FlightSummary(this.existingFlights?.inboundFlight, this._data.values.airports);
  outboundFlight = new FlightSummary(this.existingFlights?.outboundFlight, this._data.values.airports);

  inboundJourney = new JourneySummary(this.flightsToBuy?.itinerary?.inboundJourney, this.flightsToBuy?.inboundAirportTransfer);
  outboundJourney = new JourneySummary(this.flightsToBuy?.itinerary?.outboundJourney, this.flightsToBuy?.outboundAirportTransfer);

  recordLocator = this.flightsToBuy?.recordLocator;
  tickets = this.flightsToBuy?.tickets;

  allAirlines = this._data.values.airlines;

  inboundAirlines = this.allAirlines
    .filter(({ code }) => code && this.inboundJourney.airlineCodes.includes(code))
    .filter((airline, _index, self) => self.some(exist => exist.code === airline.code));

  outboundAirlines = this.allAirlines
    .filter(({ code }) => code && this.outboundJourney.airlineCodes.includes(code))
    .filter((airline, _index, self) => self.some(exist => exist.code === airline.code));

  isTheSameAirlines = this.inboundAirlines.length === this.outboundAirlines.length &&
    this.inboundAirlines.map(({ code }) => code).join() === this.outboundAirlines.map(({ code }) => code).join();

  isFlight = !!this.existingFlights?.inboundFlight;
  isJourney = !!this.flightsToBuy?.itinerary?.inboundJourney;

  constructor(
    private readonly _confirmation: ConfirmationService,
    private readonly _data: DataService
  ) {
  }
}
