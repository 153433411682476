import { Component, HostBinding } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { MemberService } from '@member/member.service';
import { BreadCrumbsItem } from '@shared/components-v2/common/bread-crumbs/bread-crumbs.component';

@Component({
  selector: 'app-member-activity-v2',
  templateUrl: './member-activity.component.html',
  standalone: false
})
export class MemberActivityV2Component extends ComponentBase {
  constructor(
    private readonly _member: MemberService,
    service: ComponentBaseService
  ) {
    super(service);
    this.init();
  }

  @HostBinding('class') hostClasses = ['w-100', 'flex-stretch-stretch', 'h-100-min'];
  isLoading = false;
  errorMessage?: string;
  breadCrumbs: BreadCrumbsItem[] = [{ caption: 'caption' }];

  init() {
    //TODO: implement
  }
}
