import { Component, Input } from '@angular/core';
import { Airport, AirportTransfer, Journey, NonMonetaryDiscount, Package, RatePlanType, RoomType, SpecialOffer } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';
import { cartAddOn } from '@shared/consts/animations';
import { tripProtectionCategory } from '@shared/consts/common';
import { AddOnToPurchaseViewModel, WithId } from '@shared/models/common';
import { AddOnService } from '@shared/services/add-on.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { PackageService } from '@shared/services/package.service';
import { PricingService } from '@shared/services/pricing.service';
import { SearchService } from '@shared/services/search/search.service';
import { TenantService } from '@shared/services/tenant.service';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-cart-viewer',
  templateUrl: './cart-viewer.component.html',
  styleUrls: ['./cart-viewer.component.scss'],
  animations: [cartAddOn.enter, cartAddOn.leave],
  standalone: false
})
export class CartViewerComponent extends ComponentBase {
  constructor(
    private readonly _search: SearchService,
    private readonly _pricing: PricingService,
    private readonly _tracking: TrackingService,
    private readonly _package: PackageService,
    private readonly _tenant: TenantService,
    readonly addOn: AddOnService,
    services: ComponentBaseService,
  ) {
    super(services);

    this.pushSub(
      _search.setWhenStateChanged(() => this._initSearchData()),
      this._package.setWhenDataChanged(() => this._initPackageData()),
      addOn.setWhenDataChanged(result => {
        this._trackChanges();
        this.addOns = result.toPurchaseViewModel;
      })
    );
  }

  // if true the component will be looking like on the mobile always
  @Input() isOnlyMobileView = false;
  @Input() isVisibleHeader = true;
  @Input() hideHeader = false;

  ratePlanType?: RatePlanType;
  roomType?: RoomType;
  selectedPackage?: WithId<Package>;
  specialOffer?: SpecialOffer;
  inboundJourney?: Journey;
  outboundJourney?: Journey;
  addOns: AddOnToPurchaseViewModel[] = [];
  tripProtectionCategory = tripProtectionCategory;
  isCouplesTenant = this._tenant.isCouplesTenant;
  periodNights!: number;
  arrivalAirport?: Airport;

  inboundAirportTransfer?: AirportTransfer;
  inboundAirportTransferTotal = 0;

  outboundAirportTransfer?: AirportTransfer;
  outboundAirportTransferTotal = 0;

  adults = 0;
  children = 0;

  flightTotal = 0;
  roomTotal = 0;
  addOnsTotal = 0;

  nonMonetary: NonMonetaryDiscount[] = [];

  private _initPackageData() {
    this._trackChanges();

    const data = this._package;
    this.ratePlanType = data.ratePlanType;
    this.roomType = data.roomType;
    this.selectedPackage = data.selectedPackage;
    this.specialOffer = data.specialOffer;
    this.inboundJourney = data.flight?.itinerary?.inboundJourney;
    this.outboundJourney = data.flight?.itinerary?.outboundJourney;
    this.periodNights = data.periodNights;

    this.inboundAirportTransfer = data.inboundAirportTransfer;
    this.inboundAirportTransferTotal = this._pricing.inboundAirportTransferTotal;

    this.outboundAirportTransfer = data.outboundAirportTransfer;
    this.outboundAirportTransferTotal = this._pricing.outboundAirportTransferTotal;

    this.nonMonetary = this._package.getNonMonetaryDiscounts(data.selectedPackage);
  }

  private _trackChanges() {
    if (this.isOnConfirmationPage) {
      return;
    }

    const { roomType, selectedPackage, inboundAirportTransfer, outboundAirportTransfer } = this._package;

    if (this.roomType?.roomTypeId !== roomType?.roomTypeId
      || this.roomTotal !== this._pricing.roomTotal) {
      this._tracking.updateRoomType();
    }

    if (this.selectedPackage?.id !== selectedPackage?.id
      || this.flightTotal !== this._pricing.flightTotal) {
      this._tracking.updateFlight();
    }

    if (this.inboundAirportTransfer?.id !== inboundAirportTransfer?.id
      || this.inboundAirportTransferTotal !== this._pricing.inboundAirportTransferTotal) {
      this._tracking.updateInboundAirportTransfer();
    }

    if (this.outboundAirportTransfer?.id !== outboundAirportTransfer?.id
      || this.outboundAirportTransferTotal !== this._pricing.outboundAirportTransferTotal) {
      this._tracking.updateOutboundAirportTransfer();
    }

    if (this.addOnsTotal !== this._pricing.addOnsTotal) {
      this._tracking.updateAddons();
    }

    this._setTrackingData();
  }

  private _setTrackingData() {
    this.flightTotal = this._pricing.flightTotal;
    this.roomTotal = this._pricing.roomTotal;
    this.addOnsTotal = this._pricing.addOnsTotal;
  }

  private _initSearchData() {
    this._trackChanges();

    this.adults = this._search.getTotalAdults();
    this.children = this._search.getTotalChildren();
    this.arrivalAirport = this._search.state.context.arrivalAirport;
  }
}
