import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { AddOnResponse } from '@shared/api/be-api.generated';
import { CarouselImage, WithId } from '@shared/models/common';

@Component({
  selector: 'app-addon-details',
  templateUrl: './addon-details.component.html',
  standalone: false
})
export class AddonDetailsComponent {
  @Input() currentAddOn!: AddOnResponse;
  @Input() images: WithId<CarouselImage>[] = [];
  @ContentChild('pricing') pricing!: TemplateRef<unknown>;
}
