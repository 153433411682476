import { Component } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { UnavailabilityReasonCode } from '@shared/models/common';
import { BlockOverlayService } from '@shared/services/block-overlay.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { ComponentsCommunicationService } from '@shared/services/components-communication.service';
import { PackageService } from '@shared/services/package.service';
import { SearchService } from '@shared/services/search/search.service';

@Component({
  selector: 'app-search-layout',
  templateUrl: './search-layout.component.html',
  styleUrls: ['./search-layout.component.scss'],
  standalone: false
})
export class SearchLayoutComponent extends ComponentBase {
  constructor(
    componentsCommunication: ComponentsCommunicationService,
    blockOverlay: BlockOverlayService,
    services: ComponentBaseService,
    search: SearchService,
    _package: PackageService,
  ) {
    super(services);

    this.pushSub(
      search.setWhenContextChanged(() => this.isRequestedFlights = false),
      componentsCommunication.flightsEnabled.subscribe(result =>
        this.isRequestedFlights = result.isEnabled),
      _package.setWhenDataChanged(({ unavailabilityReason, resort }) => {
        this.nights = _package.periodNights;

        switch (unavailabilityReason?.code as UnavailabilityReasonCode) {
          case 'MinStayPolicy':
            this.minimumStay = unavailabilityReason?.value;
            break;
          default:
            this.minimumStay = undefined;
        }

        if (this.initialArrivalChanged === undefined && resort?.phone) {
          this.initialArrivalChanged = search.initialArrivalChanged;
          this.resortPhone = resort?.phone;
        }
      })
    );

    blockOverlay.isOpened && blockOverlay.close();
  }

  isRequestedFlights = false;
  minimumStay?: string;
  nights?: number;
  initialArrivalChanged?: boolean; // need to skip showing same message a few times
  resortPhone?: string;
}
