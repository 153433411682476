import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FilterGroupItem } from '@search/components/shared/filter-group/filter-group.component';
import { AddOnService } from '@shared/services/add-on.service';
import { AddonFilterGroup, AddonFilterScope, addonFilterScopes } from './addon-filter';
import { ComponentsCommunicationService } from '@shared/services/components-communication.service';

@Component({
  selector: 'app-addon-filter',
  templateUrl: './addon-filter.component.html',
  styleUrls: ['./addon-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AddonFilterComponent {
  falseString = Number(false).toString();
  trueString = Number(true).toString();

  readonly translateKeyPrefix = 'addOn.filter'
  groups: AddonFilterGroup[] = this._getFilterGroups();

  constructor(
    private readonly _addOn: AddOnService,
    private readonly _comm: ComponentsCommunicationService,
    private readonly _cdr: ChangeDetectorRef,
  ) { }

  reset() {
    this._addOn.resetFilters();
    this._addOn.setAddOns();

    setTimeout(() => {
      this.groups = this._getFilterGroups();
      this._cdr.detectChanges();
    });
  }

  select(scope: AddonFilterScope, item: FilterGroupItem) {
    const getBooleanValue = () => item.value ? item.value === this.trueString : undefined;

    switch (scope) {
      case 'bySelection': this._addOn.showPurchased = getBooleanValue(); break;
      case 'byAvailability': this._addOn.showAvailable = getBooleanValue(); break;
      case 'byCategory': this._addOn.category = item.value; break;
    }

    this._addOn.setAddOns();
    this._comm.addonFilterUpdated.emit();
  }

  private _getFilterGroups(): AddonFilterGroup[] {
    const { categories, showPurchased: showSelected, showAvailable, category } = this._addOn;
    const getAnyOption = (): FilterGroupItem => ({ captionKey: 'any', value: undefined });

    const getBooleanOptions = () => [
      getAnyOption(),
      { value: this.falseString, captionKey: this.falseString },
      { value: this.trueString, captionKey: this.trueString }
    ];

    const allCategories = this._addOn.availableAddOnsCategories;
    const itemsValues: Record<AddonFilterScope, FilterGroupItem[]> = {
      bySelection: getBooleanOptions(),
      // except sold out
      byAvailability: getBooleanOptions().filter(item => item.value !== this.falseString),
      byCategory: [
        getAnyOption(),
        ...categories.map(value => ({
          value,
          caption: allCategories?.find(category => category.code === value)?.name
        }))
          .filter(item => item.caption)
      ],
    };

    const selectedValuesDeciders: Record<AddonFilterScope, ((item: FilterGroupItem) => boolean) | undefined> = {
      bySelection: item => showSelected === undefined || Number(showSelected).toString() === item.value,
      byAvailability: item => showAvailable === undefined || Number(showAvailable).toString() === item.value,
      byCategory: item => category === undefined || category === item.value
    }

    return addonFilterScopes.map((scope => ({
      scope,
      items: itemsValues[scope],
      selectedItem: itemsValues[scope].find(selectedValuesDeciders[scope] || (() => true)),
    })));
  }
}
