import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'position',
  standalone: false
})
export class PosistionPipe implements PipeTransform {
  transform(isLeft: boolean) {
    return isLeft ? 'start' : 'end';
  }
}
