import { Component, ContentChild, EventEmitter, Input, OnChanges, Output, TemplateRef } from '@angular/core';
import { BlockOverlayService } from '@shared/services/block-overlay.service';

export type SidebarLayoutScope = 'filter' | 'flight' | 'addOn' | 'cart' | 'account' | 'termsAndConditions' | 'member';

export type SidebarLayoutMemberSubScope = 'login' | 'menu';
export type SidebarLayoutSubScope = SidebarLayoutMemberSubScope;

@Component({
  selector: 'app-sidebar-layout',
  templateUrl: './sidebar-layout.component.html',
  styleUrls: ['./sidebar-layout.component.scss'],
  standalone: false
})
export class SidebarLayoutComponent implements OnChanges {
  @Input() scope?: SidebarLayoutScope;
  @Input() subScope?: SidebarLayoutSubScope;
  @Output() closeStarted = new EventEmitter<void>();

  @ContentChild('header') header!: TemplateRef<unknown>;
  @ContentChild('content') content!: TemplateRef<unknown>;
  @ContentChild('footer') footer!: TemplateRef<unknown>;
  @Input() isFooter = true;

  headerText!: string;
  subheader!: string;

  @Input() headerValue?: string;
  @Input() subheaderValue?: string;

  constructor(
    readonly blockOverlay: BlockOverlayService
  ) { }

  ngOnChanges(): void {
    this.headerText = this.getScopeKey('header');
    this.subheader = this.getScopeKey('subheader');
  }

  getScopeKey(key: string) {
    return `sidebar.${[this.scope, this.subScope].filter(item => item).join('.')}.${key}`;
  }

  close() {
    this.blockOverlay.close();
    this.closeStarted.emit()
  }
}
