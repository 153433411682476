<ng-container *transloco="let t">
  @if(simple){
  <div class="flex-between"
    (click)="openAutocomplete()">
    <app-icon-button
      [stroked]="disabled ? 'textInactive' : airport?.code || showFirstByDefault ? 'textPrimary' : 'selected'"
      filled="default"
      name="airplane"
      [iconSize]="iconSize"
      alt="airport.name"
      [disabled]="disabled"
      [rotate]="90"
      [prefix]="true">
    </app-icon-button>
    <label class="cursor-pointer">
      {{airport?.displayText}}
    </label>
    <app-icon
      [stroked]="disabled ? 'textInactive' : 'selected'"
      name="replace"
      class="cursor-pointer"
      size="big">
    </app-icon>
  </div>
  }
  <mat-form-field
    [hidden]="simple"
    class="display-airport-form"
    [class.full-width-mobile]="isFullWidthMobile"
    (click)="openAutocomplete()">

    <app-icon-button
      [stroked]="disabled ? 'textInactive' : airport?.code || showFirstByDefault ? 'textPrimary' : 'selected'"
      filled="default"
      name="airplane"
      [iconSize]="iconSize"
      alt="airport.name"
      [disabled]="disabled"
      [prefix]="true">
    </app-icon-button>

    <label hidden [for]="type">{{t('airport.name')}}</label>

    <input
      [id]="type"
      #input
      [placeholder]="t(['airport', 'placeholder', this.type] | join)"
      (click)="onBlur($event)"
      [disabled]="disabled"
      readonly
      matInput
      [class.color-selected]="!disabled && !airport?.code && !showFirstByDefault"
      type="text"
      [ngModel]="airport?.displayText" />

  </mat-form-field>

  <mat-form-field
    class="airport-autocomplete-form-field"
    *ngIf="airportSettings.isActive">

    <app-icon-button
      name="search"
      stroked="iconInactive"
      iconSize="small"
      filled="default"
      buttonClass="disabled-button"
      [prefix]="true">
    </app-icon-button>

    <input
      type="text"
      autofocus="true"
      [placeholder]="t(['airport', 'placeholder', this.type] | join)"
      matInput
      (appInit)="initAutoCompleteInput($event)"
      [ngModel]="airportSettings.current"
      (input)="onChange($event)"
      [matAutocomplete]="airportAutoComplete">

    <app-icon-button
      name="close"
      stroked="iconInactive"
      buttonClass="disabled-button"
      [postfix]="true"
      (click)="onClear()"
      alt="clear">
    </app-icon-button>

    <mat-autocomplete
      (optionSelected)="optionSelected($event)"
      [autoActiveFirstOption]="true"
      #airportAutoComplete="matAutocomplete"
      [displayWith]="displayWith">
      <mat-option
        *ngIf="noFlight"
        [value]="noFlight">
        {{noFlight.displayText}}
      </mat-option>
      <mat-option
        class="separator"
        [disabled]="true">
      </mat-option>
      <mat-option
        *ngFor="let option of airportsSuggestions; trackBy: 'code' | createTrackBy"
        [value]="option">
        {{option.displayText}}
      </mat-option>
    </mat-autocomplete>

  </mat-form-field>

  <div class="mat-drawer-backdrop"
    [class.mat-drawer-shown]="airportSettings.isActive"
    (click)="airportSettings.inactivate()">
  </div>
</ng-container>
