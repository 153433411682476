import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { INonMonetaryDiscount, NonMonetaryDiscount } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';

@Component({
  selector: 'app-non-monetary-v2',
  templateUrl: './non-monetary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class NonMonetaryV2Component extends ComponentBase {
  @HostBinding('class.hide-if-empty') hideIfempty = true;
  private _nonMonetaryData: NonMonetaryDiscount[] = [];
  isAnyNonMonetary = false;

  nonMonetaryView: {
    memberWithAmount: INonMonetaryDiscount[]
    offerWithAmount: INonMonetaryDiscount[],

    member: INonMonetaryDiscount[],
    offer: INonMonetaryDiscount[]
  } = {
      member: [],
      offer: [],
      memberWithAmount: [],
      offerWithAmount: []
    };

  @Input() set nonMonetary(data: NonMonetaryDiscount[]) {
    this._nonMonetaryData = data;
    this.updateView();
  }

  updateView() {
    this.nonMonetaryView = {
      member: [],
      memberWithAmount: [],
      offer: [],
      offerWithAmount: []
    };

    this.isAnyNonMonetary = this._nonMonetaryData.length > 0;

    this._nonMonetaryData.forEach(item => {
      const set = item.amount
        ? (item.membershipKind ? this.nonMonetaryView.memberWithAmount : this.nonMonetaryView.offerWithAmount)
        : (item.membershipKind ? this.nonMonetaryView.member : this.nonMonetaryView.offer);

      const exists = set.find(setItem => setItem.text === item.text);
      if (exists && item.amount) {
        exists.amount = (exists.amount || 0) + (item.amount || 0);
      }
      else if (!exists) {
        set.push({ ...item });
      }
    });
  }
}
