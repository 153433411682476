<ng-container *transloco="let t">
  <div class="member-register">
    <div class="flex-column gap-half">
      <span class="text-1-1_25-600 margin-top-half">
        {{t('member.reward.ourSave')}}
      </span>
      <ng-container *ngTemplateOutlet="rewardBullets"></ng-container>
      <span class="text-1-1_25-600 margin-top-half">
        {{t('member.reward.createAccount')}}
      </span>
    </div>
    <app-form #form
      scope="member.register"
      [form]="formGroup">
      <ng-template #content let-text="text">
        <div class="email-value">
          <ng-container *ngTemplateOutlet="text; context: {
            field: 'email',
            disabled: isLoading
          }"></ng-container>
        </div>
        <div class="password-value">
          <ng-container *ngTemplateOutlet="text; context: {
              field: 'password',
              type: 'password',
              isSubLabel: true,
              disabled: isLoading
          }"></ng-container>
        </div>
      </ng-template>
    </app-form>
    <div class="flex-start-start gap-quarter agreement">
      <app-icon
        (click)="toggleAgreementSwitcher()"
        name="checked"
        size="default"
        [class.active]="isAgree"
        [class.bc-background]="isAgree"
        class="checkbox-icon">
      </app-icon>
      <div class="text-0_875-1_5-500">
        <span class="cursor-pointer"
          (click)="toggleAgreementSwitcher()">
          {{t('member.reward.agreement')}}
          <b class="color-required">*</b>
        </span>
      </div>
    </div>
    <div class="flex border-top padding margin-top-double margin-horizontal-minus full-width-plus-padding-double">
      <button color="primary"
        [disabled]="!isAgree || isLoading"
        (click)="register()"
        mat-raised-button
        class="select-button w-100">
        @if (isLoading) {
        <app-loading-icon size="button"></app-loading-icon>
        &nbsp;
        }
        {{t('member.register.button')}}
      </button>
    </div>
    @if (errorMessage) {
    <div class="flex w-100">
      <div class="error-message text-0_75-0_875-600">
        {{ errorMessage }}
      </div>
    </div>
    }
  </div>
</ng-container>