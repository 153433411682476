import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlightResponse, Journey } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';
import { unique } from '@shared/common';
import { AnchorType } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { CabinTypePipe } from 'src/app/pipes/cabin-type.pipe';

@Component({
  selector: 'app-flight-details',
  templateUrl: './flight-details.component.html',
  styleUrls: ['./flight-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FlightDetailsComponent extends ComponentBase {
  @Input() set flight(value: FlightResponse | undefined) {
    this.flightId = value?.id;
    this.inboundJourney = value?.itinerary?.inboundJourney || undefined;
    this.outboundJourney = value?.itinerary?.outboundJourney || undefined;
    const flights = [...this.inboundJourney?.flights || [], ...this.outboundJourney?.flights || []];
    this.airlines = unique(flights.map(flight => flight.marketingAirlineName || ''))
      .join(', ')
    this.cabinTypes = unique(flights.map(flight => flight.cabinType || ''))
      .map(cabinType => this._cabinTypePipe.transform(cabinType, false))
      .join(', ');
  }

  @Input() isSelected = false;

  airlines = '';
  cabinTypes = '';
  inboundJourney?: Journey;
  outboundJourney?: Journey;
  isOpenedDetails = false;
  flightId?: string;
  readonly anchorType: AnchorType = 'flight';

  constructor(
    private readonly _cabinTypePipe: CabinTypePipe,
    services: ComponentBaseService
  ) {
    super(services);
  }

  toggleDetails() {
    this.isOpenedDetails = !this.isOpenedDetails;
    if (this.flightId && !this.isOpenedDetails) {
      this.scrollTo(this.anchorType, this.flightId);
    }
  }
}
