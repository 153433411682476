import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AddOnResponse } from '@shared/api/be-api.generated';
import { maxCountAddonsToPurchase } from '@shared/consts/common';
import { AddOnAgeGroupQuantityChange, AddOnToPurchaseViewModel, AgeGroupViewModel, UnavailabilityReason } from '@shared/models/common';
import { AddOnService } from '@shared/services/add-on.service';

@Component({
  selector: 'app-addon-age-groups-pricing',
  templateUrl: './addon-age-groups-pricing.component.html',
  styleUrls: ['./addon-age-groups-pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AddonAgeGroupsPricingComponent implements OnChanges {
  @Input() currentAddOn!: AddOnResponse;
  @Input() unavailabilityReason?: UnavailabilityReason;
  @Output() countToPurchaseChanged = new EventEmitter<AddOnAgeGroupQuantityChange>();

  addOnsToPurchase!: AddOnToPurchaseViewModel[];
  ageGroups: AgeGroupViewModel[] = [];
  maxCountToPurchase = maxCountAddonsToPurchase;

  constructor(
    private readonly _addon: AddOnService
  ) {
  }

  ngOnChanges(): void {
    this.addOnsToPurchase = this._addon.getToPurchaseViewModels(this.currentAddOn);
    const newAgeGroups = (this.currentAddOn.pricingModel?.prices || [])
      .map(item => {
        const quantity = this.addOnsToPurchase.find(addOn => addOn.ageGroupId === item.id)?.quantity || 0;
        const from = item.agePeriod?.fromYear || 0;
        const to = item.agePeriod?.toYear || 0;

        return new AgeGroupViewModel({ ...item, from, to, quantity });
      });

    this.ageGroups.length !== newAgeGroups.length
      ? this.ageGroups = newAgeGroups // initial setup
      : this.ageGroups.forEach((ageGroup, index) => // update
        ageGroup.quantity = newAgeGroups[index].quantity);
  }

  increase(item: AgeGroupViewModel) {
    const { id: ageGroupId } = item;
    if (!this.unavailabilityReason && ageGroupId && item.quantity < this.maxCountToPurchase) {
      item.quantity += 1;
      this.countToPurchaseChanged.emit({ ageGroupId, quantity: item.quantity });
    }
  }

  decrease(item: AgeGroupViewModel) {
    const { id: ageGroupId } = item;
    if (!this.unavailabilityReason && ageGroupId && item.quantity) {
      item.quantity -= 1;
      this.countToPurchaseChanged.emit({ ageGroupId, quantity: item.quantity });
    }
  }
}
