import { Component, HostBinding, Input, TemplateRef, ViewChild } from '@angular/core';
import { Package, RatePlanType, Resort, RoomType } from '@shared/api/be-api.generated';
import { flightChange } from '@shared/consts/animations';
import { perPageFlights } from '@shared/consts/common';
import { AnimationComponentBase } from '@shared/base/animation-component.base';
import { WithId } from '@shared/models/common';
import { PackageService } from '@shared/services/package.service';
import { SearchService } from '@shared/services/search/search.service';
import { SectionTogglerComponent } from '@shared/components/common/section-toggler/section-toggler.component';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { PricingDetailsConfig } from '@search/components-v2/shared/pricing-details/pricing-details.component';

@Component({
  selector: 'app-flight-select',
  templateUrl: './flight-select.component.html',
  styleUrls: ['./flight-select.component.scss'],
  animations: [flightChange],
  standalone: false
})
export class FlightSelectComponent extends AnimationComponentBase {
  @ViewChild('toggler') toggler?: SectionTogglerComponent;
  @ViewChild('flightFilter') flightFilter!: TemplateRef<unknown>;
  @HostBinding('class.hide-if-empty') hideIfEmpty = true;
  @Input() hideHeader = false;

  resort?: Resort;
  ratePlanType?: RatePlanType;
  roomType?: RoomType;
  selectedPackage?: WithId<Package>;
  totalFlights = 0;
  perPageFlights = perPageFlights;

  fligtsPage = 1;
  isNext = false;
  isPrev = false;
  from = 0
  to = 0;

  recommendedFlightIndex = -1;
  recommendedPackages: WithId<Package>[] = [];

  visiblePackages: WithId<Package>[] = [];
  allPackages: WithId<Package>[] = [];

  isVisibleLoadMore = false;
  isRequestedLoadMore = false;
  isNextAnimatingOnInit = false;
  isAnimatingRecommended = false;
  isAnimatingList = false;

  get topPackageId() {
    return this.visiblePackages?.find(() => true)?.id;
  }

  isFlightSearchAllowed!: boolean;
  pricingConfigs: Record<string, PricingDetailsConfig> = {};

  constructor(
    private readonly _sidebar: SidebarService,
    private readonly _search: SearchService,
    private readonly _package: PackageService,
    services: ComponentBaseService
  ) {
    super(services);

    this.pushSub(
      this._package.setWhenDataChanged(result => this._initData(result)),
      this._search.setWhenStateChanged(() =>
        this._search.state.event.type === 'PATCH_FLIGHT_SEARCH' && (this.isNextAnimatingOnInit = true)),
      _search.setWhenContextChanged(() =>
        this.isFlightSearchAllowed = _search.isFlightSearchAllowed)
    );
  }

  private _initData(data: PackageService) {
    const {
      resort, ratePlanType, roomType, totalFlights, isLastEventLoadMore,
      selectedPackage, recommendedPackage, packages, unavailabilityReason
    } = data;

    const previousTopPackageId = this.topPackageId;
    const isDifferentHotel = this.resort && resort?.resortId != this.resort?.resortId;

    this.resort = resort;
    this.ratePlanType = ratePlanType;

    this._updateRecommended(recommendedPackage);

    this.allPackages = packages?.filter(({ id }) => id !== recommendedPackage?.id) || [];
    this.pricingConfigs = [...this.allPackages, ...this.recommendedPackages]
      .map(item => {
        const result = new PricingDetailsConfig(item.id);
        result.active = selectedPackage?.id === item.id;
        result.disabled = !!unavailabilityReason;
        result.blocked = result.active;
        return result;
      })
      .reduce((result, item) => ({ ...result, [item.id]: item }), {});

    this.roomType = roomType;
    this.selectedPackage = selectedPackage;

    this.totalFlights = totalFlights;
    this.isVisibleLoadMore = packages?.length !== this.totalFlights;

    const isLoadMoreNextPageRequired = isLastEventLoadMore && this.isRequestedLoadMore;
    const selectedPackageIndex = this.allPackages.findIndex(item => item.id === selectedPackage?.id);

    this.fligtsPage = isDifferentHotel ? 1
      : isLoadMoreNextPageRequired ? this.fligtsPage + 1
        : selectedPackageIndex > -1
          ? Math.trunc((selectedPackageIndex / this.perPageFlights) + 1)
          : this.fligtsPage;

    this.isRequestedLoadMore = false;

    this._setPackages();

    if (this.topPackageId !== previousTopPackageId || this.isNextAnimatingOnInit) {
      this._animateList();
      this.isNextAnimatingOnInit = false;
    }

    if (isLoadMoreNextPageRequired) {
      this._scrollToTopFlight();
    }
  }

  private _setPackages() {
    this.from = (this.fligtsPage - 1) * this.perPageFlights;
    this.to = this.from + this.perPageFlights > this.totalFlights
      ? this.totalFlights
      : this.from + this.perPageFlights;

    // case when change room type and packages are not loaded
    if (this.from >= (this.allPackages?.length || 0) && this.fligtsPage !== 1) {
      this.fligtsPage = 1;
      this._setPackages();
    } else {
      this.visiblePackages = [...(this.allPackages?.slice(this.from, this.to) || [])];
    }

    this.isNext = (this.allPackages?.length || 0) - 1 > this.fligtsPage * this.perPageFlights;
    this.isPrev = this.fligtsPage > 1;
  }

  onSelectClick(newPackage: WithId<Package>) {
    this._package.selectPackage(newPackage);
  }

  loadMore() {
    if (this.roomType) {
      this._search.loadMore(this.roomType);
      this.isRequestedLoadMore = true;
    }
  }

  openFilter() {
    this._sidebar.open({
      template: this.flightFilter,
      position: 'end',
      width: 'default'
    });
  }

  changePage(next: boolean) {
    if (next) {
      if (this.isNext) {
        this.fligtsPage++;
      } else {
        this.fligtsPage = 1;
      }
    } else {
      if (this.isPrev) {
        this.fligtsPage--;
      } else {
        this.fligtsPage = Math.trunc(this.allPackages.length / this.perPageFlights) || 1
      }
    }

    this._setPackages();
    this._scrollToTopFlight();
  }

  private _scrollToTopFlight() {
    this.scrollTo('flight', 'list');
  }

  private _updateRecommended(recommendedPackage?: WithId<Package>) {
    const isRecommendedChanged = this.recommendedPackages.length
      && (this.recommendedPackages[0].id !== recommendedPackage?.id
        || this.recommendedPackages[0].finalPrice !== recommendedPackage?.finalPrice)

    if (isRecommendedChanged) {
      this._animateRecommended();
    }

    this.recommendedPackages = recommendedPackage ? [recommendedPackage] : [];
  }

  private _animateList() {
    if (this.isBrowser) {
      setTimeout(() => {
        this.isAnimatingList = !this.isAnimatingList;
        setTimeout(() => this.isAnimatingList = false, 1000);
      }, 100);
    }
  }

  private _animateRecommended() {
    if (this.isBrowser) {
      setTimeout(() => {
        this.isAnimatingRecommended = !this.isAnimatingRecommended;
        setTimeout(() => this.isAnimatingRecommended = false, 1000);
      }, 100);
    }
  }
}
