import { Component, Input } from '@angular/core';
import { BookingEngineHtmlContent } from '@shared/api/be-api.generated';

@Component({
  selector: 'app-notification-warning',
  templateUrl: './notification-warning.component.html',
  styleUrls: ['./notification-warning.component.scss'],
  standalone: false
})
export class NotificationWarningComponent {
  @Input() warning!: BookingEngineHtmlContent;
}
