import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SsrHelperService } from '@shared/services/ssr-helper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class NotFoundComponent implements OnInit {
  constructor(
    private readonly _ssr: SsrHelperService
  ) {
  }

  ngOnInit() {
    if (this._ssr.isBrowser && location.pathname === '/') {
      if (environment.production) {
        if (environment.productUrl) {
          window.location.href = environment.productUrl;
        }
      } else
        location.href = location.href + '/test';
    }
  }
}
