<ng-container *transloco="let t">
  @if(ratePlanType && (currentPackage || !hideWhenUnavailable)){
  <div [ngClass]="['package-pricing', size]"
    [@packagePriceChange]="isAnimating">
    @if (memberDiscount){
    <div class="header savings margin-bottom-half">
      <app-info-label
        [text]="t('member.savings', { value: (memberDiscount | appCurrency) })"
        type="memberDefault"
        textColor="memberText">
      </app-info-label>
    </div>
    }
    <div class="header">
      <app-info-label
        [size]="size"
        [text]="ratePlanType.name"
        type="ratePlanType">
      </app-info-label>
      <div class="header-bold">
        {{periodNights | nights }}
      </div>
    </div>
    @if(isLoading){
    <div class="price">
      <app-icon class="loading-icon"
        name="dots"
        filledCircle="selected"
        size="custom">
      </app-icon>
    </div>
    }
    @else {
    <div class="price">
      @if (currentPackage){
      @if (basePrice !== finalPrice){
      <div class="price-base">
        {{basePrice | appCurrency}}
      </div>
      }
      <div class="price-final"
        #tooltip="matTooltip"
        (mouseenter)="$event.stopImmediatePropagation()"
        (mouseleave)="$event.stopImmediatePropagation()"
        (click)="tooltip.show()"
        matTooltipPosition="below"
        [matTooltip]="t(['price', currentPriceFormat, 'tooltip'] | join)">
        {{finalPrice | appCurrency}}
      </div>
      }
      <div class="price-format"
        [matTooltip]="t(['price', currentPriceFormat, 'tooltip'] | join)"
        *ngIf="!notDisplayFormats.includes(currentPriceFormat) && currentPackage">
        &nbsp;{{t(['price', currentPriceFormat, size === 'big' ? 'formatBig' : 'format'] | join)}}
      </div>
      @if (!currentPackage && unavailabilityReason){
      <div class="price">
        <div class="not-found">{{t('price.' + unavailabilityReason.code, { value: unavailabilityReason.value})}}</div>
      </div>
      }
    </div>
    }
  </div>
  }
  <ng-template #price
    let-value="value">
    <span class="price-format">
      {{ value | formatPrice: periodNights : guestsCount : roomsCount: currentPriceFormat }}
    </span>
  </ng-template>
</ng-container>