import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PriceFormat, PriceFormatPlace } from '@shared/models/common';
import { environment } from 'src/environments/environment';
import { Tenant, TenantSettings } from 'src/environments/environment-type';

@Injectable({ providedIn: 'root' })
export class TenantService {
  constructor(
    route: ActivatedRoute,
    router: Router
  ) {
    const getRouteTenant = () => route.snapshot.firstChild?.params['tenantId'] as Tenant;
    this.update(getRouteTenant());

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const newTenant = getRouteTenant();
        if (this.id !== newTenant) {
          this.update(newTenant);
        }
      }
    });
  }

  tenantUpdated: EventEmitter<void> = new EventEmitter();
  readonly couplesTenants: Tenant[] = ['couples', 'test'];

  id!: Tenant;
  settings!: TenantSettings;
  isCouplesTenant!: boolean;
  isTestTenant!: boolean;
  priceFormat!: Record<PriceFormatPlace, PriceFormat>;
  loyaltyEnabled!: boolean;
  membershipProvider?: string;
  membershipEnabled = false;

  private update(newTenant: Tenant) {
    this.id = newTenant;
    this.isCouplesTenant = this.couplesTenants.indexOf(this.id) > -1;
    this.isTestTenant = this.id == 'test';
    this.settings = environment.tenants[this.id] || {};
    const { main = 'total', button = 'prpn', mainFlights = 'total' } = this.settings.priceFormat || {};
    this.priceFormat = { main, button, mainFlights };
    this.defaultMember = environment.isTest ? this.settings.defaultMember : undefined;
    this.loyaltyEnabled = !!this.settings.loyalty;
    this.membershipProvider = this.settings.membershipProvider;
    this.membershipEnabled = !!this.membershipProvider;
    this.tenantUpdated.emit();
  }

  defaultMember?: { email: string, password: string };

}
