import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ConfirmationService } from '@confirmation/services/confirmation.service';
import { Color } from '@shared/models/common';
import { DataService } from '@shared/services/data.service';

@Component({
  selector: 'app-notification-failed-payment-part',
  styleUrls: ['./notification-failed-payment-part.component.scss'],
  templateUrl: './notification-failed-payment-part.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class NotificationFailedPaymentPartComponent {
  @Input() noPaddingAround = false;
  @Input() borderColor!: Color;
  @Input() backgroundColor!: Color;
  @Input() textColor!: Color;
  @Input() iconName!: string;

  resort = this._data.values.resorts
    .find(resort => resort.resortId === this._confirmation.booking.accommodation?.resortId);
  phone = this.resort?.phone;

  paid = this._confirmation.paid;
  unpaid = this._confirmation.unpaid;

  constructor(
    private readonly _confirmation: ConfirmationService,
    private readonly _data: DataService
  ) { }
}
