import { Component } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { jamaicaAirportsCodes, jamaicaCode } from '@shared/consts/common';
import { ConfirmationService } from '@confirmation/services/confirmation.service';
import { DataService } from '@shared/services/data.service';

@Component({
  selector: 'app-notification-passenger',
  templateUrl: './notification-passenger.component.html',
  styleUrls: ['./notification-passenger.component.scss'],
  standalone: false
})
export class NotificationPassengerComponent extends ComponentBase {
  resortId?: string;
  isVisible = false;

  constructor(
    data: DataService,
    confirmation: ConfirmationService,
    services: ComponentBaseService
  ) {
    super(services);

    this.pushSub(
      confirmation.setWhenInitialized(({ bookingDetails: { contract, accommodation } }) => {
        const isContractForJamaica = (contract?.restrictedToCountries || []).includes(jamaicaCode);

        this.resortId = accommodation?.resortId;
        const resort = data.values.resorts.find(item => item.resortId === this.resortId);
        const isJamaicaAirport = !!resort && jamaicaAirportsCodes.some(code => resort.airports?.some(item => item.code == code));

        this.isVisible = !isContractForJamaica && isJamaicaAirport;
      })
    );
  }

  toggleVisiblity() {
    this.isVisible = !this.isVisible;
  }
}
