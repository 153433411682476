import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { PackageService } from '@shared/services/package.service';
import { BookingEngineHtmlContent, Package, RatePlanType, Resort, RoomType, SpecialOffer } from '@shared/api/be-api.generated';
import { CarouselImage, htmlContentCodes, WithId } from '@shared/models/common';
import { ComponentBase } from '@shared/base/component.base';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { HtmlContentService } from '@shared/services/html-content.service';
import { TenantService } from '@shared/services/tenant.service';
import { MemberService } from '@member/member.service';
import { PricingDetailsConfig } from '@search/components-v2/shared/pricing-details/pricing-details.component';
import { FormatPricePipe } from 'src/app/pipes/format-price.pipe';

@Component({
  selector: 'app-room-viewer',
  templateUrl: './room-viewer.component.html',
  styleUrls: [
    './scss/room-viewer.component.desktop.scss',
    './scss/room-viewer.component.mobile.scss',
  ],
  standalone: false
})
export class RoomViewerComponent extends ComponentBase {
  constructor(
    private readonly _formatPrice: FormatPricePipe,
    private readonly _htmlContent: HtmlContentService,
    private readonly _package: PackageService,
    private readonly _sidebar: SidebarService,
    private readonly _tenant: TenantService,
    private readonly _member: MemberService,
    services: ComponentBaseService
  ) {
    super(services);
    super.pushSub(
      this._package.setWhenDataChanged(() => this.setData()),
      this._member.onMemberChanged(({ profile }) => this.isMember = !!profile)
    );
  }

  @ViewChild('packageDetails') packageDetails!: TemplateRef<unknown>;
  @ViewChild('hotelierMessage') hotelierMessage!: TemplateRef<unknown>;
  @Input() hideHeader = false;
  isActive = true;
  isRecommended = false;

  ratePlanType?: RatePlanType;
  images: WithId<CarouselImage>[] = [];
  selectedPackage?: WithId<Package>;
  specialOffers?: SpecialOffer[];
  specialOffer?: SpecialOffer;
  roomType?: RoomType;
  resort?: Resort;

  isLoadingHtmlContent = false;
  hotelierMessageItem?: BookingEngineHtmlContent;

  priceFormat = this._tenant.priceFormat.main;
  isMember = !!this._member.profile;
  isMemberNonMonetary = false;

  pricingConfig!: PricingDetailsConfig;
  showBonus = false;

  setData() {
    this.ratePlanType = this._package.ratePlanType;
    this.images = this._package.images;
    this.selectedPackage = this._package.selectedPackage;
    this.specialOffers = this._package.specialOffers;
    this.specialOffer = this._package.specialOffer;
    this.roomType = this._package.roomType;
    this.resort = this._package.resort;
    this.isRecommended = this.selectedPackage?.id === '0'
      && this.roomType?.roomTypeId === this._package.roomTypes.find(() => true)?.roomTypeId;

    this.isMemberNonMonetary = !!this._package.getNonMonetaryDiscounts(this.selectedPackage, true)?.length;

    if (this.roomType?.roomTypeId) {
      this.pricingConfig = new PricingDetailsConfig(this.roomType.roomTypeId);
      this.pricingConfig.disabled = !this._package.selectedPackage;
      this.pricingConfig.blocked = true;
      if (!this.ratePlanType?.flightsIncluded) {
        this.pricingConfig.text = this.getPricingText(this.selectedPackage);
      }
    }
  }

  selectSpecialOffer(event: MatRadioChange) {
    this._package.selectSpecialOffer(event.value);
  }

  openPackageDetails(showBonus = false) {
    this.showBonus = showBonus;
    this._sidebar.open({
      template: this.packageDetails,
      position: 'end',
      width: 'big'
    });
  }

  clickSubheaderLink() {
    this.setHotelierMessage();

    this._sidebar.open({
      template: this.hotelierMessage,
      position: 'end',
      width: 'big'
    });
  }

  setHotelierMessage() {
    this.isLoadingHtmlContent = true;
    this.pushSub(this._htmlContent.get({
      skipFullLoad: true,
      code: htmlContentCodes.hotelierMessage,
      onLoaded: result => {
        this.isLoadingHtmlContent = false;
        this.hotelierMessageItem = result.find(() => true);
      }
    }));
  }

  getPricingText(currentPackage?: Package) {
    const text = this._formatPrice.transform(
      currentPackage?.finalPrice,
      this._package.periodNights,
      this._package.guestsCount,
      this._package.roomsCount,
      this.services.tenant.priceFormat.button,
      'full'
    );

    return text;
  }
}
