import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

const start = 'start' as const;
const center = 'center' as const;
const end = 'end' as const;
const full = 'full' as const;

type RecordPosition = typeof start | typeof center | typeof end | typeof full;

@Component({
  selector: 'app-confirmation-section-record',
  templateUrl: './confirmation-section-record.component.html',
  styleUrls: ['./confirmation-section-record.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ConfirmationSectionRecordComponent {
  positions = { start, center, end, full };

  @Input() isDivider: { top?: boolean, bottom?: boolean } = { bottom: true };
  @Input() isSpace?: { top?: boolean, bottom?: boolean } = { bottom: false, top: false };
  // means position of content value
  @Input() position: RecordPosition = center;
  @Input() scope = '';
  @Input() field = '';

  @Input() htmlClasses: {
    row: string,
    label: string,
    value: string,
  } = {
      row: 'confirmation-section-record',
      label: 'record-label',
      value: 'record-value'
    };

  // can be used only if position = 'full'
  @Input() isGap = true;

  // can be used only if position != 'full'
  @Input() labelWidth = '35%';
  @Input() defaultLabelWidth = '';
  @Input() labelWidthPositions: RecordPosition[] = [center, end];
}
