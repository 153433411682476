import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Booking, GuestDetails } from '@shared/api/be-api.generated';
import { DataService } from '@shared/services/data.service';
import { ConfirmationService } from '@confirmation/services/confirmation.service';

interface GuestView {
  name: string;
  email?: string;
  phone?: string;
  adultNumber?: number;
  childNumber?: number;
  isChild: boolean;
  age?: number;
  index: number;
}

@Component({
  selector: 'app-booking-summary',
  templateUrl: './booking-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class BookingSummaryComponent {
  _booking = this._confirmation?.values.bookingDetails || new Booking();

  confirmationNumber = this._booking.bookingNumber;
  bookingDate = this._booking.bookingDate;
  guests: GuestView[] = [];

  ratePlanType = this._data.values.ratePlanTypes.find(rpt =>
    rpt.ratePlanTypeId === this._booking.accommodation?.ratePlanTypeId);

  paymentPlan = this._booking.payment?.paymentPolicy?.name;
  organizationName = this._data.tradingName;

  adultNumber = 0;
  childNumber = 0;
  isGuestAdult = ({ age }: GuestDetails) => (age || -1) === -1
  getNextAdultNumber = (guest: GuestDetails) => this.isGuestAdult(guest) ? this.adultNumber = this.adultNumber + 1 : undefined;
  getNextChildNumber = (guest: GuestDetails) => !this.isGuestAdult(guest) ? this.childNumber = this.childNumber + 1 : undefined;


  memberData = this._confirmation.values.bookingDetails.memberData;
  loyaltyId = this._confirmation.values.bookingDetails.loyaltyId;

  constructor(
    private readonly _confirmation: ConfirmationService,
    private readonly _data: DataService
  ) {
    this.guests = this._booking.guestDetails?.map((guest, index) => ({
      name: [guest.firstName, guest.middleName, guest.lastName].filter(item => item).join(' '),
      email: guest.email,
      phone: guest.phone,
      adultNumber: this.getNextAdultNumber(guest),
      childNumber: this.getNextChildNumber(guest),
      isChild: !this.isGuestAdult(guest),
      age: guest.age,
      index
    })) ?? [];
  }
}
