import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BookingEngineHtmlContent } from '@shared/api/be-api.generated';

@Component({
  selector: 'app-notification-travel',
  templateUrl: './notification-travel.component.html',
  styleUrls: ['./notification-travel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class NotificationTravelComponent {
  @Input() safetyProtocols?: BookingEngineHtmlContent;
}
