import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FlightItinerary, NonMonetaryDiscount, Package, RatePlanType, Resort, RoomType } from '@shared/api/be-api.generated';
import { itemsWithId, createCarouselImages } from '@shared/common';
import { CarouselImage, WithId } from '@shared/models/common';
import { PackageService } from '@shared/services/package.service';
import { ComponentBase } from '@shared/base/component.base';
import { BlockOverlayService } from '@shared/services/block-overlay.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { ComponentsCommunicationService } from '@shared/services/components-communication.service';

@Component({
  selector: 'app-package-details',
  templateUrl: './package-details.component.html',
  styleUrls: ['./package-details.component.scss'],
  standalone: false
})
export class PackageDetailsComponent extends ComponentBase implements OnChanges, AfterViewInit {
  constructor(
    private readonly _package: PackageService,
    private readonly _blockOverlay: BlockOverlayService,
    private readonly _comm: ComponentsCommunicationService,
    services: ComponentBaseService
  ) {
    super(services);

    this.ratePlanType = this._package.ratePlanType
    this.resort = this._package.resort;
    this.roomType = this._package.roomType;
  }

  @ViewChild('bonus') bonusSection?: ElementRef
  @HostBinding('class.block') block = true;
  @Input() currentRoomType!: RoomType;
  @Input() currentPackage?: WithId<Package>;
  @Input() showBonus = false;

  resort?: Resort;
  ratePlanType?: RatePlanType;
  flight?: FlightItinerary;
  images: WithId<CarouselImage>[] = [];
  amenities: WithId<{ item: string }>[] = [];
  roomType?: RoomType;
  nonMonetary: NonMonetaryDiscount[] = [];

  ngAfterViewInit() {
    if (this.showBonus) {
      window.setTimeout(() => (this.bonusSection?.nativeElement as HTMLElement)
        .scrollIntoView({ behavior: 'smooth' }), 500);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentPackage']) {
      this.flight = this.currentPackage ? this._package.getFlight(this.currentPackage) : undefined;
      this.nonMonetary = this._package.getNonMonetaryDiscounts(this.currentPackage);
    }

    if (changes['currentRoomType']) {
      this.amenities = itemsWithId(this.currentRoomType?.amenities?.map(item => ({ item })));
      this.images = createCarouselImages(this.currentRoomType);
    }
  }

  closeSidebar() {
    this._blockOverlay.close();
  }

  selectFlight() {
    this.closeSidebar();
    this.isOnSearchPageV1 && this.scrollTo('section', 2);
    this.isOnSearchPageV2 && this._comm.openFlightsSection.emit();
  }
}
