<!-- <div class="under-search-bar" *ngIf="isVisibleAirports"></div> -->
<ng-container *transloco="let t">
  <div class="search-stepper">
    <div class="search-stepper-container">
      <mat-stepper #stepper
        [linear]="false"
        [(selectedIndex)]="selectedIndex"
        [labelPosition]="(stepperLabelOrientation | async)!">
        <ng-template #commonHeader
          let-showPrevious="showPrevious"
          let-settings="settings">
          <!-- [class.hidden-tablet-max]="showPrevious" -->
          <div class="section-header step-content-header"
            [class.without-previous]="showPrevious && !settings.previous"
            [class.with-previous]="showPrevious"
            [class.margin-less-bottom]="settings.marginLessBottom && !showPrevious">
            @if (showPrevious) {
            @if (settings.previous) {
            <button mat-raised-button
              class="select-button step-button"
              color="primary"
              matStepperPrevious>
              <app-icon
                stroked="default"
                name="previous"
                size="small">
              </app-icon>
              {{t('searchSection.previous.' + settings.previous)}}
            </button>
            }
            }
            @else {
            <div class="section-header-text">
              <app-anchor type="section" index="1"></app-anchor>
              {{settings.number}}. {{t(settings.header)}}
            </div>
            }
            <div class="section-header-buttons-container"
              [@stepperNextButtonChange]>
              @if (roomType && pricingVisible[currentSection]) {
              <app-pricing-details-v2
                [class.hidden-ipod-max]="showPrevious && settings.previous"
                [config]="pricingConfig"
                [size]="(pricingSize | async)!"
                class="big-not-found">
                <app-package-pricing
                  [priceFormat]="priceFormat"
                  [hideWhenUnavailable]="false"
                  [isActive]="pricingConfig.active"
                  [currentPackage]="selectedPackage"
                  [currentRoomType]="roomType">
                </app-package-pricing>
              </app-pricing-details-v2>
              }

              @if (settings.next) {
              <button mat-raised-button
                class="select-button next-button"
                color="primary"
                matStepperNext>
                {{t('searchSection.next.' + settings.next)}}
                <app-icon
                  stroked="default"
                  name="next"
                  size="small">
                </app-icon>
              </button>
              }
              @else if(!showPrevious) {
              <app-booking-button
                class="hidden-tablet-min"
                [profile]="profile"
                [simple]="true">
              </app-booking-button>
              }
            </div>
          </div>
        </ng-template>

        @for (section of availableSections; track $index) {
        <mat-step [ngClass]="section">
          <ng-template matStepLabel>
            <div class="not-selectable" [innerHTML]="t('searchSection.' + section)"></div>
          </ng-template>

          @if (section !== 'review') {
          <ng-container *ngTemplateOutlet="commonHeader; context: {
            settings: sectionSettings[section]
          }"></ng-container>
          }

          <ng-template matStepContent>
            @switch (section) {
            @case (allSection.packages) {
            @if (!isFlights) {
            <app-notification-flight-v2></app-notification-flight-v2>
            }
            <app-room-viewer [hideHeader]="true"></app-room-viewer>
            @if (isFlights) {
            <app-notification-amenities></app-notification-amenities>
            }
            }
            @case (allSection.flights) {
            <app-flight-select [hideHeader]="true"></app-flight-select>
            }
            @case (allSection.addons) {
            <app-addon-select [hideHeader]="true"></app-addon-select>
            }
            @case (allSection.transfer) {
            <app-airport-transfer-select-options [hideHeader]="true">
            </app-airport-transfer-select-options>
            <app-addon-select [isOrderedSection]="false"></app-addon-select>
            }
            @case (allSection.review) {
            <app-cart-summary>
              <ng-template #header>
                <ng-container *ngTemplateOutlet="commonHeader; context: {
                  settings: sectionSettings[section]
                }"></ng-container>
              </ng-template>
            </app-cart-summary>
            }
            }

            <ng-container *ngTemplateOutlet="commonHeader; context: {
              settings: sectionSettings[section],
              showPrevious: true
            }"></ng-container>
          </ng-template>
        </mat-step>
        }
      </mat-stepper>
    </div>
  </div>
</ng-container>