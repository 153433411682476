import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ErrorComponent {
  @HostBinding('class.full-screen-container') isFullScreen = true;

  @Input() errorString? = '';

  reload() {
    location.href = location.href;
  }
}
