<ng-container *transloco="let t">
  <div class="guest-details padding-bottom border-formControl-bottom-dotted margin-top"
    *ngIf="guestForm">
    <div class="block-header">
      <div class="block-header-text">
        {{t(guestType +'.header', { value: guestNumber })}}
      </div>
    </div>
    @if (personsOptions.length && !isMainAdult) {
    <div class="flex-start gap margin-top-half">
      <div class="flex-1">
        <mat-form-field>
          <mat-select
            [placeholder]="t('guest.select')"
            (selectionChange)="setPerson($event.value)">
            @for (person of personsOptions; track person.value) {
            <mat-option
              [value]="person">
              {{person.label}}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex-1 hidden-desktop-max"></div>
    </div>
    }
    <div class="adult-details-content">
      <app-form
        #form
        scope="guest"
        [form]="guestForm"
        [anchorNumber]="[guestType, guestNumber].join('-')"
        [anchorType]="anchorType"
        (selectChanged)="selectChange($event)">

        <ng-template #content
          let-text="text"
          let-label="label"
          let-select="select"
          let-caption="caption"
          let-autocomplete="autocomplete">

          <ng-container #templates>
            <ng-template #country let-field="field">
              <ng-container *ngTemplateOutlet="label; context: { field }"></ng-container>
              <ng-container *ngTemplateOutlet="select; context:{ field, options: countries }"></ng-container>
            </ng-template>
          </ng-container>

          <ng-container #blocks>
            <div class="flex-between-end flex-column-desktop-max gap-desktop-min">
              @for (namePart of name; track $index) {
              @if(isFlightSelected || namePart !== 'middleName'){
              <div [ngClass]="[namePart, 'flex-100']">
                <ng-container *ngTemplateOutlet="text; context: { field: namePart, disabled: memberDisabled }"></ng-container>
              </div>
              }
              }
            </div>

            @if (isDOB){
            <div>
              <div class="flex-column">
                <div class="label"
                  *ngIf="isValidDob; else dobError">
                  <ng-container *ngTemplateOutlet="caption; context: { field: 'dob', required: true }"></ng-container>
                </div>
                <ng-template #dobError>
                  <div class="label">
                    <app-anchor
                      *ngIf="anchorType && firstDobErrorField"
                      [type]="anchorType"
                      [index]="[[guestType, guestNumber].join('-'), firstDobErrorField]">
                    </app-anchor>
                    <mat-error class="error-message bold">
                      {{t('guest.error.dob', { field: dobErrorFields | join: ', ' })}}
                    </mat-error>
                  </div>
                </ng-template>
              </div>
              <div class="flex-between-end flex-column-desktop-max gap">
                <div [ngClass]="[dobPart, 'flex-100']"
                  *ngFor="let dobPart of dob">
                  <ng-container *ngTemplateOutlet="select; context: {
                    field: dobPart,
                    options: dobOptions[dobPart],
                    hideLabel: true,
                    trackId: 'value'
                  }">
                  </ng-container>
                </div>
              </div>
            </div>
            }

            <div class="flex-between-end flex-column-desktop-max gap-desktop-min">
              <div class="flex-100"
                *ngIf="isContactInfoRequired">
                <ng-container *ngTemplateOutlet="text; context: { field: 'phone', required: true }"></ng-container>
              </div>
              <div class="flex-100"
                *ngIf="isFlightSelected || isMainAdult">
                <ng-container *ngTemplateOutlet="autocomplete; context: { field: 'country', options: countriesOptions }"></ng-container>
              </div>
            </div>

            @if (isMainAdult){
            <!-- <ng-container *ngTemplateOutlet="autocomplete; context: {
              field: 'address',
              options: addressOptions,
              isLoading: isLoadingAddress,
              isSeparatedButton: true
            }">
            </ng-container> -->
            <div class="flex-column">
              <div class="flex-between flex-column-ipod-max gap-ipod-min">
                <div class="street flex-100">
                  <ng-container *ngTemplateOutlet="text; context: { field: 'street', required: true }"></ng-container>
                </div>
                <div class="city flex-100">
                  <ng-container *ngTemplateOutlet="text; context: { field: 'city', required: true }"></ng-container>
                </div>
              </div>
              <div class="flex-between flex-column-ipod-max gap-ipod-min">
                <div class="state flex-100">
                  <ng-container *ngTemplateOutlet="text; context: { field: 'state', required: true }"></ng-container>
                </div>
                <div class="postcode flex-100">
                  <ng-container *ngTemplateOutlet="text; context: {
                      field: 'postcode',
                      required: true,
                      labelKey: isPostcode ? 'postcode' : 'zipcode'
                  }"></ng-container>
                </div>
              </div>
            </div>
            }
            <div class="flex-column cursor-pointer">
              <ng-container *ngTemplateOutlet="label; context: { field: 'gender'}"></ng-container>
              <div class="value flex-start-start gap-double">
                <div [ngClass]="[gender, 'flex-start gap-half']"
                  *ngFor="let gender of genders"
                  [@genderChange]="animatingStateGender[gender]"
                  (click)="genderChange(gender)">
                  <app-icon
                    name="checked"
                    size="default"
                    [class.active]="genderValues[gender] === form.get['gender']()"
                    [class.bc-selected]="genderValues[gender] === form.get['gender']()"
                    class="select-button select-icon padding-less">
                  </app-icon>
                  <div class="not-selectable">
                    {{t('guest.genders.'+ gender)}}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </app-form>
    </div>
  </div>
</ng-container>
