<ng-container *transloco="let t">
  @if(isActive){
  <div [ngClass]="['full-size', 'bc-'+ overlayColor]"
    (click)="closeBanner($event.target)">
    <div class="cursor-pointer h-100-min"
      [ngClass]="['flex-' + positionHorizontal + '-' + positionVertical]">
      <div [ngClass]="[wrapClass]">
        <div class="flex-column box-shadow cursor-default gap"
          [style.max-width]="maxWidth + unit"
          [ngClass]="'padding-' + paddingSize + ' bc-' + background">
          @if (header){
          <ng-container *ngTemplateOutlet="header"></ng-container>
          }
          @if (content){
          <ng-container *ngTemplateOutlet="content"></ng-container>
          }
          @else {
          <ng-content></ng-content>
          }
          <div class="banner-button margin-top">
            @if (footer){
            <ng-container *ngTemplateOutlet="footer"></ng-container>
            }
            @else {
            <button mat-raised-button
              color="primary"
              class="select-button w-100"
              (click)="hideBanner()">
              {{t('button.ok' )}}
            </button>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
  }
</ng-container>
