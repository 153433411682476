import { CarouselImage, PriceFormat, SelectOption, WithId, availablePriceFormats } from './models/common';
import { RoomType, Room, Address, Resort, AgePeriod, AirportTransfer } from '@shared/api/be-api.generated'
import { ElementRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { defaultAdultsCount, maximunYearsFromNow, minimumDaysFromNow } from './consts/common';
import { AddOnResponse } from '@shared/api/be-api.generated';
import { concat, last, Observable, toArray } from 'rxjs';
import { DateTime } from 'luxon';

export function withId<T>(item: T, id: string | number): WithId<T> {
  return { id: id.toString(), ...item }
}

export function itemsWithId<T>(items?: T[] | undefined): WithId<T>[] {
  return items ? items.map((item, index) => withId(item, index)) : [];
}

export function includes<T>(item: T, ...items: T[]) {
  return items.includes(item);
}

export function unique<T>(item: T[]) {
  return item?.filter((value, index, self) => !!value && self.indexOf(value) === index) || [];
}

export const createCarouselImages = ({ images, name }: Resort | AddOnResponse | RoomType | AirportTransfer | undefined = new RoomType()) =>
  itemsWithId(images?.map(({ mainUrl: image, thumbnailUrl: thumbImage }) =>
    ({ image, thumbImage, alt: name } as CarouselImage)));

export function scrollHorizontally(toLeft: boolean, container: ElementRef, total: number) {
  const element = container.nativeElement as HTMLElement;
  const roomOptionWidthPx = element?.firstElementChild?.clientWidth || 0;
  const fullItemsScrolled = Math.round(element.scrollLeft / roomOptionWidthPx);

  const left = toLeft
    ? element.scrollLeft < roomOptionWidthPx ? 0 : (fullItemsScrolled - 1) * roomOptionWidthPx
    : (total - 1) * roomOptionWidthPx < element.scrollLeft ? (total - 1) * roomOptionWidthPx : (fullItemsScrolled + 1) * roomOptionWidthPx;

  if (left === element.scrollLeft) { }

  element.scrollTo({
    left,
    behavior: 'smooth'
  });
}

export function arrayFrom<T>(length: number | undefined, mapFunc: (index: number) => T) {
  return length ? Array.from({ length }).map((_, index) => mapFunc(index)) : [];
}

export function scrollToId({ anchorId, timeout, behavior = 'smooth' }: { anchorId?: string, timeout?: number, behavior?: 'smooth' | 'auto' }) {
  if (anchorId) {
    const scrollAction = () => {
      const allAnchors = document.querySelectorAll('#' + anchorId)
      if (allAnchors.length > 0) {
        allAnchors[allAnchors.length - 1]?.scrollIntoView({ behavior });
      }
    }
    if (timeout !== undefined) {
      setTimeout(() => scrollAction(), timeout);
    } else {
      scrollAction();
    }
  }
}

export function createFormControl(initialState: string | undefined = '', ...validators: ValidatorFn[]) {
  return new UntypedFormControl(initialState, [Validators.required, ...validators]);
}

export const createChecker = <T>({ input, hasAnyValue = false }: { input: T, hasAnyValue?: boolean }) => {
  const isArray = () => Array.isArray(input);
  const isObject = () => input && typeof input === 'object' && (hasAnyValue || Object.keys(input).length > 0);
  const isString = () => typeof input === 'string' && (hasAnyValue || !!input);
  const isNumber = () => typeof input === 'number' && (hasAnyValue || !!input); // hasAnyValue means not 0
  const isBoolean = () => typeof input === 'boolean';
  const isValue = () => isString() || isNumber() || isArray() || isObject() || isBoolean();

  return { isArray, isObject, isString, isNumber, isValue, isBoolean };
};

export const getOptionsValues = (options?: SelectOption[]) =>
  options?.map(option => option.value?.toString() || '') || [];

export const getDefaultRoom = (
  id: string | number = '0',
  adults = defaultAdultsCount,
  children: number[] = []): Room =>
  new Room({ id: id.toString(), adults, children });

export const buildResortAddress = ({ street, city, postcode, state, country }: Address | undefined = new Address()) =>
  [street, city, postcode, state, country?.name].join(', ');

export const getCountChildrenInAgePeriod = (children?: number[], agePeriod?: AgePeriod) =>
  children?.filter(age =>
    (agePeriod?.fromYear === undefined || agePeriod.fromYear <= age)
    && (agePeriod?.toYear === undefined || agePeriod.toYear >= age))?.length
  || 0;

const isObject = <T>(item: T): boolean => {
  return item !== null && typeof item === 'object';
};

const isMergebleObject = <T>(item: T): boolean => {
  return isObject(item) && !Array.isArray(item);
};

export const mergeObjects = <T extends object = object>(target: T, ...sources: T[]): T => {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();
  if (source === undefined) {
    return target;
  }

  if (isMergebleObject(target) && isMergebleObject(source)) {
    Object.keys(source)
      .forEach(function (key: string) {
        if (isMergebleObject(source[key as keyof T])) {
          if (!target[key as keyof T]) {
            target[key as keyof T] = {} as T[keyof T];
          }
          mergeObjects(target[key as keyof T] as unknown as object, source[key as keyof T] as unknown as object);
        } else {
          target[key as keyof T] = source[key as keyof T];
        }
      });
  }

  return mergeObjects(target, ...sources);
};

export const createUntypedFormGroup = <T extends string>(controls: Record<T, UntypedFormControl | undefined>) => {
  const result: Record<string, UntypedFormControl> = {};

  Object.keys(controls)
    .forEach(key => {
      const controlKey = key as T;
      const control = controls[controlKey];
      if (control && controlKey) {
        result[controlKey] = control;
      }
    });


  return new UntypedFormGroup(result);
}

export const getUrlsFromString = (input?: string): string[] => {
  const pattern = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm;
  const result = input?.match(pattern);
  return result || [];
}

export const toPriceFormat = (value?: string): PriceFormat | undefined => {
  return value && availablePriceFormats.includes(value as PriceFormat) ? value as PriceFormat : undefined;
}

export const arrayToRecord = <T extends string>(data: readonly T[]): Record<T, T> => {
  return data.reduce((all, current) =>
    ({ ...all, [current]: current }),
    {} as Record<T, T>);
}

export const concatLast = (...sources: Observable<unknown>[]) => concat(
  ...sources.map(source$ => source$.pipe(last()))
).pipe(
  toArray()
);

export const removeFromUrl = (url: string, ...queryParams: string[]) => {
  const urlParts = url.split('?');

  if (urlParts.length > 1) {
    const queryString = urlParts[1].split('&')
      .filter(item => !queryParams.some(param => item.indexOf(param) > -1))
      .join('&');

    return [urlParts[0], queryString].join('?');
  }

  return url;
}
/**
 *
 * @param to - default value is now
 * @returns number of units
 */
export const getDiff = ({ from, to = DateTime.now(), unit = 'days' }: { from?: DateTime, to?: DateTime, unit?: 'days' | 'hours' }) => {
  if (from) {
    return from > to ? from.diff(to, unit)[unit] : to.diff(from, unit)[unit];
  }

  return undefined;
}

export const minArrivalDate = DateTime.now().plus({ days: minimumDaysFromNow });
export const maxDepartureDate = DateTime.now().plus({ years: maximunYearsFromNow });
