import { Component, TemplateRef, ViewChild } from '@angular/core';
import { getPricingErrorMessage } from '@booking/services/booking.error-messages';
import { BookingService } from '@booking/services/booking.service';
import { TranslocoService } from '@jsverse/transloco';
import { BookingEngineHtmlContent, Resort } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';
import { arrayFrom } from '@shared/common';
import { htmlContentCodes, PaymentResultMessage, TranslocoInput } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { DataService } from '@shared/services/data.service';
import { HtmlContentService } from '@shared/services/html-content.service';
import { PackageService } from '@shared/services/package.service';
import { PricingService } from '@shared/services/pricing.service';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-review-booking',
  templateUrl: './review-booking.component.html',
  styleUrls: ['./review-booking.component.scss'],
  standalone: false
})
export class ReviewBookingComponent extends ComponentBase {
  constructor(
    private readonly _data: DataService,
    private readonly _booking: BookingService,
    private readonly _sidebar: SidebarService,
    private readonly _pricing: PricingService,
    private readonly _package: PackageService,
    private readonly _tracking: TrackingService,
    private readonly _transloco: TranslocoService,
    services: ComponentBaseService,
    htmlContent: HtmlContentService,
  ) {
    super(services);
    const { started, failed, success } = _booking.reservation;

    this.pushSub(
      this._package.setWhenDataChanged(({ resort }) => this.resort = resort),
      started.subscribe(() => {
        if (!this.isLoading) {
          this._toggleLoader();
        }
      }),
      failed.subscribe(error => {
        this.isLoading && this._toggleLoader();
        this._setReservationError(error.response || error.message);
      }),
      success.subscribe(({ response }) => {
        this.isBooked = true;
        this.paymentPageUrl = response.paymentPageUrl;
        this.isLoading && this._toggleLoader();
        this._booking.clearCart();
        this._openPayment();
      }),
      this._booking.postSectionCheckFailed
        .subscribe(result => this._setReservationErrorWithTranslate(result))
    );

    this.executeTest(() => this.toggleAgree());
    this.executeTest(() => this._tryScrollToBookingButton());
    //setTimeout(() => this.openTermsAndConditions(), 1000);

    htmlContent.get({
      code: htmlContentCodes.extraTerms,
      onLoaded: result => this.extraTerms = result.find(() => true)
    })
  }

  readonly termId = 'terms';

  @ViewChild('payment') payment!: TemplateRef<unknown>;
  @ViewChild('termsAndConditions') termsAndConditions!: TemplateRef<unknown>;

  reviewOptions = arrayFrom(3, index => index + 1);
  tradingName = this._data.tradingName;

  isAgree = false;
  isLoading = false;
  isBooked = false;
  extraTerms?: BookingEngineHtmlContent;
  isExtraTermsShown = false;

  reservationError?: string;
  paymentPageUrl?: string;
  resort?: Resort;

  isCreatedIFrameListener = false;

  preSectionsChecks = [
    // () => getAirportTransferErrorMessage({
    //   airportTransfers: this._package.airportTransfers,
    //   isAirportTransferSelected: this._package.isAirportTransferSelected,
    //   isFlightSelected: this._package.isFlightSelected
    // }),
    () => getPricingErrorMessage(this._pricing.total),
  ];

  openTermsAndConditions(event: Event | null) {
    if ((event?.target as HTMLElement)?.id === this.termId) {
      this._sidebar.open({
        template: this.termsAndConditions,
        width: 'big',
        position: 'end',
      });
    }
  }

  toggleAgree() {
    this.isAgree = !this.isAgree;
  }

  startBooking() {
    if (this.extraTerms) {
      this.isExtraTermsShown = true;
    }
    else {
      this._startBookingProcess();
    }
  }

  confirmExtraTerms() {
    this.isExtraTermsShown = false;
    this._startBookingProcess();
  }

  declineExtraTerms() {
    this.isExtraTermsShown = false;
  }

  private _startBookingProcess() {
    if (!this.isCreatedIFrameListener) {
      this.isCreatedIFrameListener = true;
      this._listenForIFrameEvents();
    }

    if (this._isValidReservation() && this.isAgree && !this.isLoading) { //&& !this.isBooked
      // setTimeout(() => {
      //   this._booking.reservation.success.emit({
      //     response: {
      //       paymentPageUrl: 'https://localhost:44301/test/card/index/03d23399-1fa2-4274-be6c-5c3351835f90/052b0407-f13a-42a1-bd05-54e72925e19b/32d6f93b-1e35-443e-9194-8b48fedee774'
      //     },
      //     request: new ReservationRequest()
      //   })
      // }, 1);

      this._booking.restartVerification();
    }
  }

  //#region help
  private _isValidReservation() {
    const result = this.preSectionsChecks
      .map(item => item())
      .find(item => item.key);

    this._setReservationErrorWithTranslate(result);

    return !this.reservationError;
  }

  private _tryScrollToBookingButton() {
    const timeout = this.executeTest(() => this._scrollToBookingButton(), 2500);
    if (timeout && document) {
      // when user is used scroll, no need to scroll to the booking button
      const page = document.querySelector('.page-container');
      const clearScrollToBooking = () => clearTimeout(timeout);
      page?.addEventListener('scroll', clearScrollToBooking, false)
      this.executeTest(() => page?.removeEventListener('scroll', clearScrollToBooking, false), 2600);
    }
  }

  private _scrollToBookingButton() {
    this.scrollTo('bookingButton')
  }

  private _toggleLoader() {
    this.isLoading = !this.isLoading;
  }
  //#endregion

  //#region booking process
  private _openPayment() {
    if (this.payment) {
      this._sidebar.open({
        template: this.payment,
        position: 'start',
        width: 'full'
      });
    }
  }

  private _listenForIFrameEvents() {
    if (this.isBrowser) {
      window.addEventListener('message', (event: MessageEvent<PaymentResultMessage>) => {
        const { result, bookingId, message } = event.data;
        if (bookingId) {
          this._sidebar.close();

          if (result) {
            this._tracking.markPurchaseTrackingAsRequired();
            !this.isLoading && this._toggleLoader();
            !this.isOnConfirmationPage && this.services.navigation.openNext(bookingId);
          } else if (message) {
            this._setReservationError(message);
          }
        }
      });
    }
  }

  private _setReservationError(message: string) {
    this.reservationError = message;
    this._scrollToBookingButton();
  }

  private _setReservationErrorWithTranslate(input?: TranslocoInput) {
    this.reservationError = input?.key
      ? this._transloco.translate(input.key, input.params)
      : undefined;
  }
  //#endregion
}

