import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Pipe({
  name: 'nights',
  standalone: false
})
export class NightsPipe implements PipeTransform {
  constructor(
    private readonly _transloco: TranslocoService
  ) { }

  transform(nights: number) {
    const key = nights === 1 ? 'price.night.singular' : 'price.night.plural';
    return this._transloco.translate(key, { value: nights });
  }
}
