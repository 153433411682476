import { Component, ContentChild, TemplateRef } from '@angular/core';
import { MemberService } from '@member/member.service';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { PackageService } from '@shared/services/package.service';

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss'],
  standalone: false
})
export class CartSummaryComponent extends ComponentBase {
  constructor(
    private readonly _package: PackageService,
    private readonly _member: MemberService,
    services: ComponentBaseService
  ) {
    super(services);
    super.pushSub(
      this._member.onMemberChanged(() => this.profile = this._member.profile),
      this._package.setWhenDataChanged(({ selectedPackage }) => this.isSelectedPackage = !!selectedPackage)
    );
  }

  isSelectedPackage = !!this._package.selectedPackage;
  profile = this._member.profile;
  @ContentChild('header') header?: TemplateRef<unknown>;
}
