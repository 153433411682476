import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ConfirmationService } from '@confirmation/services/confirmation.service';
import { NavigationService } from '@shared/services/navigation.service';

@Component({
  selector: 'app-notification-booking',
  templateUrl: './notification-booking.component.html',
  styleUrls: ['./notification-booking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class NotificationBookingComponent {
  @Input() bookingId?: string;
  @Input() isBookingCancelled?: boolean;
  @Input() isBookingSecured?: boolean;
  @Input() activeResortPhone?: string;

  isFullyPaid = !!this.bookingId && this._confirmation.unpaid <= 0;

  constructor(
    private readonly _confirmation: ConfirmationService,
    private readonly _navigation: NavigationService,
  ) {
  }

  openSearch() {
    this._navigation.openSearch();
  }
}
