import { Component, HostBinding, Input } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { MemberService } from '@member/member.service';
import { BookingService } from '@booking/services/booking.service';
import { MembershipProfile } from '@shared/api/be-api.generated';

const menu = ['profile', 'bookings', 'loyalty', 'help'] as const;
export type MemberMenuItem = typeof menu[number];

@Component({
  selector: 'app-member-info-v2',
  templateUrl: './member-info.component.html',
  standalone: false
})
export class MemberInfoV2Component extends ComponentBase {
  constructor(
    private readonly _member: MemberService,
    private readonly _booking: BookingService,
    services: ComponentBaseService
  ) {
    super(services);

    this.setLoyalty(this._member.profile);
    this.onDestroyHandler = () => {
      this.setLoyalty(undefined);
      this._booking.clearCart();
    };

    this.pushSub(
      _member.onMemberChanged(({ profile }) => this.setLoyalty(profile))
    );
  }

  @Input() isShort = true;
  @HostBinding('class.hide-if-empty') hideIfEmpty = true;
  profile = this._member.profile;

  setLoyalty(profile?: MembershipProfile) {
    this.profile = this._member.profile;
    this._booking.reservationInput.setLoyalty(profile?.email, profile?.memberId);
  }
}
