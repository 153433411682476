import { Pipe, PipeTransform } from '@angular/core';
import { imagesPath } from '@shared/consts/common';


@Pipe({
  name: 'imagePath',
  standalone: false
})
export class ImagePathPipe implements PipeTransform {
  transform(path: keyof typeof imagesPath) {
    return imagesPath[path];
  }
}
