import { Component, HostBinding } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { MemberService } from '@member/member.service';
import { MemberLevelBenefit, MembershipLevel } from '@shared/api/be-api.generated';
import { BreadCrumbsItem } from '@shared/components-v2/common/bread-crumbs/bread-crumbs.component';
import { Color } from '@shared/models/common';

class MemberLevelBenefitView extends MemberLevelBenefit {
  constructor(data: MemberLevelBenefit) {
    super(data);

    this.isAnyBenefit = !!data.monetaryDiscount || !!data.percentageDiscount || !!data.resortCreditAmount || !!data.perks?.length;
  }

  isAnyBenefit = false;
}

@Component({
  selector: 'app-member-rewards-v2',
  templateUrl: './member-rewards.component.html',
  standalone: false
})
export class MemberRewardsV2Component extends ComponentBase {
  constructor(
    private readonly _member: MemberService,
    service: ComponentBaseService
  ) {
    super(service);
    this.init();
  }

  @HostBinding('class') hostClasses = ['w-100', 'flex-stretch-stretch', 'h-100-min'];
  levels: MembershipLevel[] = [];
  benefits: Record<string, MemberLevelBenefitView> = {};
  isLoading = true;
  errorMessage?: string;
  breadCrumbs: BreadCrumbsItem[] = [{ caption: 'caption' }];

  currentLevel?: MembershipLevel;
  currentLevelColor: Color = 'selected';
  nextLevel?: MembershipLevel;
  nextLevelColor: Color = 'memberDefault';

  levelSettings: Record<string, {
    color: Color,
    textShadow: 'dark' | 'light'
  }> = {

    };

  init() {
    this.pushSub(
      this._member.getMembershipLevels().subscribe({
        next: ({ levels = [], benefits = [] }) => {
          this.levels = levels;
          benefits.forEach(benefit => benefit.memberLevel
            && (this.benefits[benefit.memberLevel] = new MemberLevelBenefitView(benefit)));

          this.currentLevel = this.levels.find(item => item.code === this._member.profile?.memberLevel);
          if (this.currentLevel?.order) {
            this.nextLevel = this.levels.find(item => this.currentLevel?.order && item.order && item.order > this.currentLevel.order)
          }

          this.currentLevel?.code && (this.levelSettings[this.currentLevel.code] = {
            color: 'selected',
            textShadow: 'light'
          });

          this.nextLevel?.code && (this.levelSettings[this.nextLevel.code] = {
            color: 'textInactive',
            textShadow: 'dark'
          });

        },
        error: ex => this._member.getMembershipError(ex),
        complete: () => this.isLoading = false
      })
    );
  }
}
