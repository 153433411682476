import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Color } from '@shared/models/common';

export interface RecommendationBarSettings {
  textColor: Color;
  backgroundColor: Color;
  textKey: string;
}

@Component({
  selector: 'app-recommendation-bar-v2',
  templateUrl: './recommendation-bar.component.html',
  styleUrls: ['./recommendation-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class RecommendationBarV2Component implements RecommendationBarSettings {
  @Input() textKey!: string

  _backgroundColor!: Color;
  @Input() set backgroundColor(value: Color) {
    this._backgroundColor = value;
    this._setBackgroundColorClass(value);
  }
  get backgroundColor(): Color {
    return this._backgroundColor;
  }

  _textColor!: Color;
  @Input() set textColor(value: Color) {
    this._textColor = value;
    this._setTextColorClass(value);
  }

  get textColor(): Color {
    return this._textColor;
  }

  @Input() set settings({ textKey, backgroundColor, textColor }: RecommendationBarSettings) {
    this.textColor = textColor;
    this.backgroundColor = backgroundColor;
    this.textKey = textKey;
  }


  private readonly _backgroundColorClassPrefix = 'bc-';
  private readonly _textColorClassPrefix = 'color-';
  backgroundColorClass!: string;
  textColorClass!: string;

  private _setBackgroundColorClass(value?: Color) {
    this.backgroundColorClass = this._backgroundColorClassPrefix + (value || 'default');
  }

  private _setTextColorClass(value?: Color) {
    this.textColorClass = this._textColorClassPrefix + (value || 'textPrimary');
  }
}
