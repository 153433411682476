import { EventEmitter, Injectable } from '@angular/core';
import { RatePlanType } from '@shared/api/be-api.generated';

export type FlightsEnabledInput = {
  isEnabled: boolean;
  ratePlanType?: RatePlanType;
}

@Injectable()
export class ComponentsCommunicationService {
  flightsEnabled = new EventEmitter<FlightsEnabledInput>(); // string is requested rate plan
  openFlightsSection = new EventEmitter();
}
