import { Component } from '@angular/core';
import { SearchService } from '@shared/services/search/search.service';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { DataService } from '@shared/services/data.service';
import { RatePlanType, Resort, Room } from '@shared/api/be-api.generated';
import { MatSelectChange } from '@angular/material/select';
import { ComponentsCommunicationService } from '@shared/services/components-communication.service';
import { pages } from '@shared/models/common';
import { TenantService } from '@shared/services/tenant.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: false
})
export class HeaderComponent extends ComponentBase {
  resort?: Resort;
  guestsCount = 0;
  resorts: Resort[] = [];

  availableRatePlanTypes: RatePlanType[] = [];

  currentRatePlanType?: RatePlanType;
  availableRatePlanType?: RatePlanType;
  isActiveRatePlanTypeSwitcher = false;

  resortLogoImageURL?: string;

  constructor(
    private readonly _comm: ComponentsCommunicationService,
    private readonly _sidebar: SidebarService,
    private readonly _search: SearchService,
    private readonly _tenant: TenantService,
    services: ComponentBaseService,
    data: DataService
  ) {
    super(services);
    super.pushSub(
      data.setWhenInitialized(({ resorts, ratePlanTypes }) => {
        this.resorts = resorts;
        this.availableRatePlanTypes = ratePlanTypes || [];
        this.isActiveRatePlanTypeSwitcher = this.availableRatePlanTypes.length > 1;
      }),
      _search.setWhenContextChanged(({ resort, ratePlanType }) => {
        this.resort = resort;
        this.resortLogoImageURL =
          // tenant.id === 'test' && (resort?.name || '').toLocaleLowerCase().indexOf('couples') > -1 ? 'imagePath.couplesLogo' :
          this.resort?.logo?.thumbnailUrl;

        this.guestsCount = _search.getTotalGuests();
        this.currentRatePlanType = ratePlanType;
        this.availableRatePlanType = this.availableRatePlanTypes.find(item => item.ratePlanTypeId !== this.currentRatePlanType?.ratePlanTypeId);
        this.isActiveRatePlanTypeSwitcher = this.availableRatePlanTypes.length > 1;
      })
    );
  }

  openProfileMenu() {
    this._sidebar.openAccount();
  }

  openGuestDetails() {
    if (this.isOnSearchPage) {
      this._sidebar.openHotelFilter();
    }
  }

  compareResort(r1: Resort, r2: Resort): boolean {
    return r1.resortId === r2.resortId;
  }

  resortChanged({ value }: MatSelectChange) {
    const rooms = (this._search.state.context.rooms || [])
      .map(item => new Room({ ...item, children: [] }))
    this._search.applyHotelFilter(value, rooms);
  }

  compareRatePlanType(r1: RatePlanType, r2: RatePlanType): boolean {
    return r1.ratePlanTypeId === r2.ratePlanTypeId;
  }

  ratePlanTypeChanged({ value }: MatSelectChange) {
    this.currentRatePlanType = value as RatePlanType;
    // if it's hotel only or if it is flight changed to flight
    if (!this.currentRatePlanType?.flightsIncluded || this._search.state.context.ratePlanType?.flightsIncluded) {
      this._search.setRatePlanType(this.currentRatePlanType?.ratePlanTypeId);
    }
    else {
      this._comm.flightsEnabled.emit({
        isEnabled: true,
        ratePlanType: this.currentRatePlanType
      });
    }
  }

  changeRatePlanType() {
    if (this.isActiveRatePlanTypeSwitcher) {
      this.isActiveRatePlanTypeSwitcher = false;
      this._comm.flightsEnabled.emit({
        isEnabled: false
      });
    } else if (this.availableRatePlanType) {
      if (this.availableRatePlanType.flightsIncluded) {
        this.isActiveRatePlanTypeSwitcher = true;
        this._comm.flightsEnabled.emit({
          isEnabled: true,
          ratePlanType: this.availableRatePlanType
        });
      } else {
        this._search.setAirports();
      }
    }
  }

  toSearchV2() {
    if (this._tenant.isTestTenant) {
      this.services.navigation.open(pages.results);
    }
  }
}
