<ng-container
  *transloco="let t">
  <ng-container *ngIf="name.length">
    <ng-template #buttonContentTemplate>
      <app-icon
        *ngIf="!isLoading"
        #tooltip="matTooltip"
        [matTooltipTouchGestures]="'off'"
        (click)="tooltip.toggle()"
        [matTooltip]="!!tooltipKey ? t(tooltipKey + '.tooltip') : tooltipText"
        [matTooltipPosition]="tooltipPosition"
        (mouseenter)="$event.stopImmediatePropagation()"
        (mouseleave)="$event.stopImmediatePropagation()"
        [size]="iconSize"
        [name]="name"
        [rotate]="rotate"
        [filled]="filled"
        [filledRect]="filledRect"
        [stroked]="stroked">
      </app-icon>
      <div class="loading-icon-container"
        *ngIf="isLoading">
        <app-loading-icon size="button"></app-loading-icon>
      </div>
      <div class="button-content hide-if-empty">
        <ng-content #content></ng-content>
      </div>
    </ng-template>

    <ng-container
      *ngIf="type === 'icon'">
      <button
        *ngIf="!prefix && !postfix"
        mat-icon-button
        tabindex="-1"
        [disabled]="disabled"
        [class]="allButtonClasses"
        [attr.aria-label]="iconKey">
        <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
      </button>

      <button
        *ngIf="prefix"
        [disabled]="disabled"
        tabindex="-1"
        matPrefix
        mat-icon-button
        [class]="allButtonClasses"
        [attr.aria-label]="iconKey">
        <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
      </button>

      <button
        *ngIf="postfix"
        [disabled]="disabled"
        tabindex="-1"
        matPostfix
        mat-icon-button
        [class]="allButtonClasses"
        [attr.aria-label]="iconKey">
        <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
      </button>
    </ng-container>

    <button
      *ngIf="type === 'raised'"
      [disabled]="disabled"
      tabindex="-1"
      mat-raised-button
      [class]="allButtonClasses"
      [attr.aria-label]="iconKey">
      <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
    </button>

    <button
      *ngIf="type === 'minifab'"
      [disabled]="disabled"
      tabindex="-1"
      mat-mini-fab
      [class]="allButtonClasses"
      [attr.aria-label]="iconKey">
      <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
    </button>
  </ng-container>
</ng-container>