import { Component, HostBinding, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { imagesPath } from '@shared/consts/common';
import { CarouselImage, WithId } from '@shared/models/common';
import { SsrHelperService } from '@shared/services/ssr-helper.service';
import { NgImageSliderComponent } from 'ng-image-slider';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  standalone: false
})
export class GalleryComponent implements OnChanges {
  @Input() imagePopup = true;
  @Input() showArrow = true;
  @Input() images: WithId<CarouselImage>[] = [];

  @ViewChild('nav') slider!: NgImageSliderComponent;
  @HostBinding('class.full-size') fullSize = true;
  @HostBinding('class.block') block = true;
  readonly slideAnimationSpeedSeconds = 0.5;
  readonly imageSize = { width: '100%', height: '100%' };
  readonly infinite = true;
  autoSlide: { interval: number, stopOnHover: boolean } | boolean = false;

  activeImageIndex = 0;
  slideInterval = 0;
  isVisible = false;
  isBrowser = this._ssrHelper.isBrowser;

  constructor(
    private readonly _ssrHelper: SsrHelperService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['images']) {
      this.isVisible = false;
      const isAnyImage = !!this.images && !!this.images.length;
      this.images = isAnyImage
        ? this.images
        : [{ id: '0', image: imagesPath.noImage, thumbImage: imagesPath.noImage }];

      this.resetImages(isAnyImage);
      // this helps to reload images correctly
      setTimeout(() => this.isVisible = true);
    }
  }

  resetImages(isAnyImage: boolean) {
    this.imagePopup = this.imagePopup && isAnyImage;
    this.activeImageIndex = 0;
    this.autoSlide = this.images.length > 1 ? { interval: 5, stopOnHover: true } : false;

    if (this.slideInterval) {
      window.clearInterval(this.slideInterval)
    }
  }

  arrowClick(input: object) {
    const { action } = input as { action: 'next' | 'previous' };
    const total = (this.images || []).length;

    this.activeImageIndex = action === 'next' ?
      this.activeImageIndex === total - 1 ? 0 : this.activeImageIndex + 1 :
      this.activeImageIndex === 0 ? total - 1 : this.activeImageIndex - 1;
  }

  showImage(index: number) {
    if (index === this.activeImageIndex) {
      return;
    }

    this.slideToImage(Math.abs(index - this.activeImageIndex), index > this.activeImageIndex
      ? () => this.slider.next()
      : () => this.slider.prev());
  }

  slideToImage(times: number, slideFunc: () => void) {
    if (this.slideInterval) {
      window.clearInterval(this.slideInterval);
    }

    slideFunc();
    times--;

    if (times > 0) {
      this.slideInterval = window.setInterval(() => {
        times--;
        slideFunc();
        if (times === 0) {
          window.clearInterval(this.slideInterval);
        }
      }, this.slideAnimationSpeedSeconds * 1000 + 100);
    }
  }
}
