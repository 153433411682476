import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, HostBinding, Input, TemplateRef, ViewChild } from '@angular/core';
import { PricingDetailsConfig } from '@search/components-v2/shared/pricing-details/pricing-details.component';
import { SelectOptionConfig, SelectOptionV2Component } from '@search/components-v2/shared/select-option/select-option.component';
import { AddOnResponse } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';
import { createCarouselImages } from '@shared/common';
import { AddOnToPurchaseViewModel, AddonType } from '@shared/models/common';
import { AddOnService } from '@shared/services/add-on.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { ComponentsCommunicationService } from '@shared/services/components-communication.service';
import { PackageService } from '@shared/services/package.service';
import { SearchService } from '@shared/services/search/search.service';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';

@Component({
  selector: 'app-addon-select',
  templateUrl: './addon-select.component.html',
  styleUrls: ['./addon-select.component.scss'],
  standalone: false
})
export class AddonSelectComponent extends ComponentBase implements AfterViewInit {
  constructor(
    private readonly _addon: AddOnService,
    private readonly _sidebar: SidebarService,
    private readonly _package: PackageService,
    search: SearchService,
    services: ComponentBaseService,
    comm: ComponentsCommunicationService,
  ) {
    super(services);
    this._setBufferSize();

    this.pushSub(
      comm.addonFilterUpdated.subscribe(() => this.options?.scrollToIndex(0)),
      this._addon.setWhenDataChanged(({ currentAddOns, toPurchase, resortAddOns, isTripProtectionPurchased }) => {
        this.addOns = currentAddOns.reduce((result, item) => ({ ...result, [item.addOnId || '']: item }), {});
        this.purchasedCount = toPurchase.length - (isTripProtectionPurchased ? 1 : 0);
        this.totalResortAddOns = resortAddOns.length;

        currentAddOns.forEach(addOn => {
          const id = addOn.addOnId;
          if (!id) {
            return;
          }

          this.toPurchase[id] = this._addon.getToPurchaseViewModels(addOn);

          let selectConfig = this.selectConfigs.find(item => item.id === id);
          if (!selectConfig) {
            selectConfig = new SelectOptionConfig(id, addOn.name, addOn.description, createCarouselImages(addOn));
            this.selectConfigs.push(selectConfig);
          }

          selectConfig.disabled = !!_package.unavailabilityReason;

          let pricingConfig = this.pricingConfigs[id];
          if (!pricingConfig) {
            pricingConfig = new PricingDetailsConfig(id);
            this.pricingConfigs[id] = pricingConfig;
          }

          pricingConfig.active = !!this._addon.getToPurchaseViewModels(addOn).length;
          pricingConfig.disabled = selectConfig.disabled;
        })
      }),
      _package.setWhenDataChanged(() => {
        this.unavailabilityReason = _package.unavailabilityReason;
        this.orderNumber = search.isFlightSearchAllowed ?
          !_package.airportTransfers.length
            ? '3'
            : '4'
          : '2';
      })
    );
  }

  @HostBinding('class.hide-if-empty') hideIfEmpty = true;
  @ViewChild('options') options!: CdkVirtualScrollViewport;
  @ViewChild('addOnFilter') addOnFilter!: TemplateRef<unknown>;
  @Input() hideHeader = false;
  @Input() isOrderedSection = true;

  addOns: Record<string, AddOnResponse> = {};
  isHidden = false;
  itemSize = 300;
  minBufferPx!: number;
  maxBufferPx!: number;
  purchasedCount = 0;
  orderNumber!: string;
  totalResortAddOns!: number;
  selectConfigs: SelectOptionConfig[] = [];
  pricingConfigs: Record<string, PricingDetailsConfig> = {};
  toPurchase: Record<string, AddOnToPurchaseViewModel[]> = {};

  unavailabilityReason = this._package.unavailabilityReason;
  countToPurchase: Record<string, number> = {};
  readonly ageGroupAddonType = AddonType.PerAgeGroup;

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      setTimeout(() => {
        const options = this.options?.elementRef?.nativeElement as HTMLElement;
        const option = options?.querySelector('app-select-option-v2');
        if (option) {
          this.itemSize = option.clientWidth;
          this._setBufferSize();
        }
      });
    }
  }

  openFilter() {
    this._sidebar.open({
      template: this.addOnFilter,
      position: 'end',
      width: 'default'
    });
  }

  toggleIsHidden() {
    this.isHidden = !this.isHidden;
  }

  openPurchased() {
    this._addon.showPurchased = true;
    this._addon.setAddOns();
    this.toggleIsHidden();
  }

  private _setBufferSize() {
    this.minBufferPx = this.itemSize * 3;
    this.maxBufferPx = this.itemSize * 4;
  }

  updateCountToPurchase(addOn: AddOnResponse, newValue: number, ageGroupId?: string) {
    if (!this.unavailabilityReason && addOn?.addOnId) {
      this.countToPurchase[addOn.addOnId] = newValue;
      this.purchaseAddOn(addOn, ageGroupId);
    }
  }

  purchaseAddOn(addOn: AddOnResponse, ageGroupId?: string) {
    if (!this.unavailabilityReason && addOn?.addOnId) {
      if (!this.countToPurchase[addOn.addOnId]) {
        this.countToPurchase[addOn.addOnId] = 1;
      }

      this._addon.purchase(addOn, this.countToPurchase[addOn.addOnId], ageGroupId);
    }
  }

  selectAddOn(addOn: AddOnResponse, option?: SelectOptionV2Component) {
    if (addOn?.addOnId && this.pricingConfigs[addOn.addOnId].active) {
      this._addon.cancelPurchase(addOn);
      this.countToPurchase[addOn.addOnId] = 1;
    } else if (option && addOn.pricingModel?.type === this.ageGroupAddonType) {
      option.openDetails();
    } else {
      this.purchaseAddOn(addOn);
    }
  }
}
