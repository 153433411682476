<ng-container *transloco="let t">
  <div class="full-size overflow-hidden-x">
    <div class="block-header">
      <div class="block-header-text">
        {{t('cart.header.total.' + page)}}
      </div>
      <div class="block-header-navigation fw-600"
        [@cartPriceChange]="isAnimating">
        {{total | appCurrency}}
        @if (isCouplesTenant && isTripProtection){
        <b>&nbsp;*</b>
        }
      </div>
    </div>
    <div class="flex-between">
      <div class="text-0_625-1_125-500 color-formControl">
        ({{t('cart.subheader.tax')}})
      </div>
      @if (saved > 1) {
      <div class="margin-left-auto color-textSuccessPrice text-0_875-1-500"
        [@cartPriceChange]="isAnimating">
        {{t('cart.subheader.saved', { value: saved | appCurrency })}}
      </div>
      }
    </div>
    <div class="flex-between margin-top-quarter">
      <div class="text-0_625-1_125-500">
        {{t('cart.subheader.total')}}
      </div>
      @if (isOnSearchPage && memberSaved) {
      <div>
        <app-info-label
          [text]="t('member.savings', { value: (memberSaved | appCurrency) })"
          type="memberDefault"
          textColor="memberText">
        </app-info-label>
      </div>
      }
    </div>
  </div>
</ng-container>