<ng-container *transloco="let t">
  <div class="notification-travel flex"
    *ngIf="safetyProtocols">
    <div class="main-container">
      <app-notification
        [isThreeDots]="false"
        position="start"
        borderWidth="thick"
        borderColor="highlighted"
        backgroundColor="default">
        <div class="flex-column gap">
          <div class="block-header">
            <div class="block-header-text">
              @if (safetyProtocols.header) {
              {{safetyProtocols.header}}
              }
              @else {
              {{t('notification.safetyProtocols.header')}}
              }
            </div>
          </div>
          <div class="block-subheader"
            appTargetBlank>
            <div class="custom-html-content full-width"
              [innerHTML]="safetyProtocols.content| safe: 'html'">
            </div>
          </div>
        </div>
      </app-notification>
    </div>
  </div>
</ng-container>