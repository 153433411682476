<ng-container *transloco="let t">
  @if(isAnyNonMonetary){
  @for (item of nonMonetaryView.offerWithAmount; track $index) {
  <ng-container *ngTemplateOutlet="itemView; context: { item }"></ng-container>
  }
  @for (item of nonMonetaryView.memberWithAmount; track $index) {
  <ng-container *ngTemplateOutlet="itemView; context: { item }"></ng-container>
  }
  @for (item of nonMonetaryView.offer; track $index) {
  <ng-container *ngTemplateOutlet="itemView; context: { item }"></ng-container>
  }
  @for (item of nonMonetaryView.member; track $index) {
  <ng-container *ngTemplateOutlet="itemView; context: { item }"></ng-container>
  }
  }

  <ng-template #itemView
    let-item="item">
    <div class="flex-start text-0_875-1_5-500 gap-half">
      @if (item.membershipKind) {
      <app-info-label
        class="member-bonus"
        [text]="t('member.bonus.singular')"
        type="memberDefault"
        textColor="memberText">
      </app-info-label>
      }
      <span>
        @if (item.amount) {
        <span class="color-textSuccessPrice fw-600">
          {{item.amount | appCurrency}}&nbsp;
        </span>
        }
        {{item.text}}
      </span>

    </div>
  </ng-template>
</ng-container>