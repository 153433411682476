import { Component, Output, EventEmitter, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormComponent } from '@shared/components/common/form/form.component';
import { SearchService } from '@shared/services/search/search.service';

type PromoCodeField = 'value';

@Component({
  selector: 'app-promo-code',
  templateUrl: './promo-code.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PromoCodeComponent {
  @Output() cancelEdit = new EventEmitter();
  @ViewChild('form') fc!: FormComponent<PromoCodeField>;
  contentPromocode = this._search.state.context.promoCode;

  promoCodeForm = new UntypedFormGroup({
    value: new UntypedFormControl(this.contentPromocode || '', ({ value }) =>
      value === this.contentPromocode ? { applied: true } : null)
  });

  constructor(
    private readonly _search: SearchService,
  ) { }

  apply() {
    this.promoCodeForm.markAllAsTouched();
    if (this.promoCodeForm.valid) {
      this._search.setPromoCode(this.fc.get.value());
      this.cancel();
    }
  }

  cancel() {
    this.cancelEdit.emit();
  }
}
