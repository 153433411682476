<ng-container *transloco="let t">
  <div class="cart-viewer">
    <div class="section-header"
      [hidden]="!isVisibleHeader || hideHeader">
      <div class="section-header-text">
        {{t('cart.header.breakdown.' + page)}}
      </div>
      <app-section-toggler [hidden]="isOnlyMobileView" #toggler></app-section-toggler>
    </div>
    <div class="collapsable"
      [class.collapsed]="toggler && toggler.isHidden && !isOnlyMobileView">
      <div class="section-subheader"
        *ngIf="ratePlanType">
        <app-info-label
          [text]="ratePlanType.name"
          type="ratePlanType">
        </app-info-label>
        <div class="section-subheader-bold">
          {{t(adults > 1 ? 'adult.count.plural': 'adult.count.singular', { value: adults } )}}
          <ng-container *ngIf="children">
            {{t(children > 1 ? 'child.count.plural': 'child.count.singular', { value: children } )}}
          </ng-container>
          {{periodNights | nights}}
        </div>
      </div>
      <div class="divider-dotted"></div>
      <div class="block-header">
        <div class="block-header-text">
          {{roomType?.caption}}
          &nbsp;
        </div>
        <div class="final-price fw-500">
          {{selectedPackage?.finalPrice | appCurrency}}
        </div>
      </div>
      <app-special-offer-caption
        *ngIf="specialOffer"
        [currentSpecialOffer]="specialOffer"
        [isVisibleNotes]="false">
      </app-special-offer-caption>

      <app-non-monetary-v2
        [nonMonetary]="nonMonetary">
      </app-non-monetary-v2>

      <div class="divider-dotted" *ngIf="inboundJourney && outboundJourney"></div>
      <ng-container *ngIf="inboundJourney && outboundJourney">
        <div class="block-header block-margin">
          <div class="block-header-text">
            {{t('cart.header.flights')}}
          </div>
        </div>
        <div class="selected-flight-journey">
          <app-flight-journey
            [isOnlyMobileView]="isOnlyMobileView"
            [journey]="inboundJourney">
          </app-flight-journey>
          <app-flight-journey
            [isOnlyMobileView]="isOnlyMobileView"
            [journey]="outboundJourney">
          </app-flight-journey>
        </div>
      </ng-container>
      <ng-container *ngIf="addOns.length">
        <div class="divider-dotted"></div>
        <div class="block-header block-margin">
          <div class="block-header-text">
            {{t('cart.header.addons')}}
          </div>
        </div>
        <div class="selected-addons">
          <ng-container
            *ngFor="let addOn of addOns; trackBy: 'addOnId' | createTrackBy">
            <div class="selected-addons-item"
              [@cartAddOnEnter]
              [@cartAddOnLeave]>
              <div class="addon-name">
                {{addOn.name}}
                <div class="text-0_75-0_75-500"
                  *ngIf="addOn.ageGroupId">
                  ({{t('cart.fromTo', { from: addOn.from, to: addOn.to })}})
                </div>
              </div>

              <div class="addon-price price-text">
                <ng-container *ngIf="addOn.priceTotal > 0; else freeAddOn">
                  <div class="price-per-item">{{addOn.pricePerItem | appCurrency}}&nbsp;x&nbsp;</div>
                  <div class="quantity">{{addOn.quantity}}</div>
                  <div class="price-total">&nbsp;=&nbsp;{{addOn.priceTotal | appCurrency}}</div>
                </ng-container>
                <ng-template #freeAddOn>
                  <div class="color-textSuccessPrice">
                    {{t('price.complimentary')}}
                  </div>
                  <div class="quantity">&nbsp;x&nbsp;{{addOn.quantity}}</div>
                </ng-template>
              </div>
            </div>
            <div class="selected-addons-item"
              [@cartAddOnEnter]
              [@cartAddOnLeave]>
              <div class="addon-description"
                *ngIf="isCouplesTenant && addOn.categoryCode===tripProtectionCategory">
                <div [innerHTML]="t('couples.tripProtection.description') | safe: 'html'"></div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #airportTransferView
        let-airportTransfer="airportTransfer"
        let-totalPrice="totalPrice"
        let-type="type">
        <ng-container *ngIf="airportTransfer">
          <div class="divider-dotted"></div>
          <div class="block-header block-margin transfer">
            <div class="transfer-header-item">
              {{airportTransfer.name}}
            </div>
            <div class="transfer-price">
              <small class="color-textInactive transfer-type">
                ({{t(['airportTransfer', type] | join: '.')}})
              </small>
              <div class="block-header-navigation price-text">
                <div class="color-textSuccessPrice"
                  *ngIf="!totalPrice">
                  {{t('price.complimentary')}}
                </div>
                <div *ngIf="totalPrice">
                  {{airportTransfer.price | appCurrency}}&nbsp;x&nbsp;{{adults + children}}&nbsp;=&nbsp;{{totalPrice | appCurrency}}
                </div>
              </div>
            </div>
          </div>
          <div class="transfer-description"
            *ngIf="arrivalAirport">
            {{t( type === 'inbound' ? 'airportTransfer.fromAirportToHotel': 'airportTransfer.fromHotelToAirport', { value: arrivalAirport.name })}}
          </div>
        </ng-container>
      </ng-template>

      <ng-container *ngTemplateOutlet="airportTransferView; context: {
        airportTransfer: inboundAirportTransfer,
        totalPrice: inboundAirportTransferTotal,
        type: 'inbound'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="airportTransferView; context: {
        airportTransfer: outboundAirportTransfer,
        totalPrice: outboundAirportTransferTotal,
        type: 'outbound'
      }">
      </ng-container>

      <div class="divider-dotted margin-bottom-less"></div>
    </div>
  </div>
</ng-container>
