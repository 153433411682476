import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Journey } from '@shared/api/be-api.generated';
import { itemsWithId, unique } from '@shared/common';
import { FlightViewModel } from '@shared/models/common';
import { SsrHelperService } from '@shared/services/ssr-helper.service';
import { DateTime, Duration } from 'luxon';

@Component({
  selector: 'app-flight-journey',
  templateUrl: './flight-journey.component.html',
  styleUrls: ['./flight-journey.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FlightJourneyComponent implements OnChanges {
  @Input() isOnlyMobileView = false;
  @Input() type?: 'departure' | 'returning';
  @Input() isOpenedDetails = false;
  @Input() isDetailsMode = false;
  @Input() journey?: Journey

  marketingAirlineIconSrc = '';
  marketingAirlineName = '';

  departureTime?: DateTime;
  departureAirport?: string;
  departureDate?: DateTime;

  arrivalTime?: DateTime;
  arrivalAirport?: string;
  arrivalDate?: DateTime

  stopsCount = 0;
  totalFlightTime?: Duration;
  airlines = '';

  flights: FlightViewModel[] = [];

  longLayoverHours = 6;

  isAnyLongStop = false;

  isAllowedDetails = this._ssrHelper.isBrowser;

  constructor(
    private readonly _ssrHelper: SsrHelperService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const value = this.journey;

    if (!value && value !== changes['journey'].previousValue) {
      return;
    }

    const flights = value && value.flights || [];
    let previousArriavalTime: DateTime | undefined;

    this.flights = itemsWithId(flights).map(flight => {
      const layover = previousArriavalTime && flight.departureTime
        ? flight.departureTime?.diff(previousArriavalTime, ['days', 'hours', 'minutes'])
        : [];
      previousArriavalTime = flight.arrivalTime;
      return { ...flight, layover } as FlightViewModel;
    }) || [];

    const firstFlight = flights.find(() => true);
    const lastFlight = flights?.slice(-1).find(() => true);

    this.marketingAirlineIconSrc = flights.length
      ? `/assets/airlines/${firstFlight?.marketingAirline}.png` : '';
    this.marketingAirlineName = firstFlight?.marketingAirlineName || '';

    this.departureTime = firstFlight?.departureTime;
    this.departureAirport = firstFlight?.departureAirportCode;
    this.departureDate = firstFlight?.departureTime;

    this.arrivalTime = lastFlight?.arrivalTime;
    this.arrivalAirport = lastFlight?.arrivalAirportCode;
    this.arrivalDate = firstFlight?.arrivalTime;

    this.stopsCount = flights?.length > 1 ? flights.length - 1 : 0;
    this.totalFlightTime = value?.totalFlightTime;
    this.airlines = unique(flights?.map(flight => flight.marketingAirlineName)).join(', ');

    this.isAnyLongStop = this.flights.some(flight => (flight.layover?.hours || 0) > this.longLayoverHours);
  }
}

