import { EventEmitter, Injectable } from '@angular/core';
import { MembershipClient, MembershipProfile } from '@shared/api/be-api.generated';
import { getDiff, removeFromUrl } from '@shared/common';
import { QueryParamsService } from '@shared/services/query-params.service';
import { SsrHelperService } from '@shared/services/ssr-helper.service';
import { TenantService } from '@shared/services/tenant.service';
import { DateTime } from 'luxon';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Injectable()
export class MemberAuthService {
  constructor(
    private readonly _tenant: TenantService,
    private readonly _ssr: SsrHelperService,
    private readonly _cookie: SsrCookieService,
    private readonly _memberClient: MembershipClient,
    private readonly _queryParams: QueryParamsService,
  ) {
    this._tenant.tenantUpdated.subscribe(() => this.membershipEnabled = _tenant.membershipEnabled);
    this.authChanged.subscribe(result => this.profile = result);
    this.init();
  }

  authChanged = new EventEmitter<MembershipProfile>();
  membershipEnabled = this._tenant.membershipEnabled;
  profile?: MembershipProfile;

  static readonly Token = 'c-breez-be-jwt-token';

  init() {
    if (this.membershipEnabled) {
      if (this._queryParams.value.accessToken) {
        this.applyAccessToken(this._queryParams.value.accessToken);
        this.removeAccessTokenFromUrl();
      } else {
        this.getJwtToken() ? this.loadProfile() : this.signOut();
      }
    } else {
      this.signOut();
    }
  }

  applyAccessToken(accessToken: string) {
    this._memberClient.validateToken(this._tenant.id, accessToken).subscribe({
      next: ({ profile, token, expireIn }) => {
        this.setJwtToken(token, expireIn);
        this.authChanged.emit(profile);
      },
      error: () => this.signOut()
    });
  }

  loadProfile() {
    this._memberClient.getProfile(this._tenant.id).subscribe({
      next: profile => this.profile = profile,
      error: () => this.setJwtToken(),
      complete: () => this.authChanged.emit(this.profile)
    });
  }

  signOut() {
    this.setJwtToken();
    this.authChanged.emit();
  }

  signIn(profile?: MembershipProfile, jwtToken?: string, expireIn?: DateTime) {
    this.setJwtToken(jwtToken, expireIn);
    this.authChanged.emit(profile);
  }

  setJwtToken(jwtToken?: string, expireIn?: DateTime) {
    jwtToken
      ? this._cookie.set(MemberAuthService.Token, jwtToken, getDiff({ from: expireIn }))
      : this._cookie.delete(MemberAuthService.Token);
  }

  getJwtToken() {
    return this._cookie.get(MemberAuthService.Token) || undefined;
  }

  removeAccessTokenFromUrl() {
    if (this._ssr.isBrowser) {
      window.history.replaceState({}, document.title, removeFromUrl(location.href, 'accessToken'));
    }
  }
}
