import { Component } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { PackageService } from '@shared/services/package.service';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';

@Component({
  selector: 'app-fixed-buttons',
  templateUrl: './fixed-buttons.component.html',
  styleUrls: ['./fixed-buttons.component.scss'],
  standalone: false
})
export class FixedButtonsComponent extends ComponentBase {
  sections = []; // 1, 2, 3];
  isSelectedPackage!: boolean

  constructor(
    private readonly _sidebar: SidebarService,
    private readonly _package: PackageService,
    services: ComponentBaseService
  ) {
    super(services);

    this.pushSub(
      this._package.setWhenDataChanged(({ selectedPackage }) => {
        if (!!selectedPackage !== this.isSelectedPackage) {
          this.isSelectedPackage = !!selectedPackage;
        }
      }));
  }

  openCart() {
    this._sidebar.openCart();
  }

  scrollToSection(section: number) {
    this.scrollTo('section', section);
  }
}
