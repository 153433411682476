import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iconPath',
  standalone: false
})
export class IconPathPipe implements PipeTransform {
  transform(name: string, scope = 'icons', format = 'svg') {
    return `assets/${scope}/${name}.${format}`;
  }
}
