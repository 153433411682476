<ng-container *transloco="let t">
  <div class="review-booking flex-column gap">
    <div class="section-header">
      <div class="section-header-text">
        {{t('reviewBooking.section.header')}}
      </div>
      <app-anchor type="reviewBooking"></app-anchor>
      <app-section-toggler #toggler [hidden]="true"></app-section-toggler>
    </div>
    <div class="collapsable"
      [class.collapsed]="toggler.isHidden">
      <div class="review-booking-content flex-column gap">
        <!-- <ol class="margin-less block-description">
          <li *ngFor="let index of reviewOptions"
            [innerHtml]="t('reviewBooking.section.subheader.' + index)">
          </li>
        </ol> -->
        <div class="block-description"
          [innerHtml]="t('reviewBooking.description', { value: tradingName })">
        </div>
        <div class="flex-column bc-background padding">
          <div class="fw-600">
            {{t('reviewBooking.agreeHeader')}}
          </div>
          <div class="flex-start gap-half padding-vertical">
            <app-icon
              (click)="toggleAgree()"
              name="checked"
              size="default"
              [class.active]="isAgree"
              [class.bc-background]="isAgree"
              class="checkbox-icon cursor-pointer">
            </app-icon>
            <span class="text-0_875-1_25-500"
              [innerHTML]="t('reviewBooking.agreeOption') | safe: 'html'"
              (click)="openTermsAndConditions($event)">
            </span>
          </div>
        </div>
        <div class="agree-button-container flex-column gap">
          <app-icon-button
            name="lock"
            alt="reviewBooking.agreeButton"
            filled="default"
            [isLoading]="isLoading"
            [customButtonSize]="true"
            [disabled]="!isAgree || isLoading"
            (click)="startBooking()"
            [transparent]="false"
            [buttonClass]="'select-button active w-100 ' + (isLoading ? 'loading' : '')"
            type="raised">
            {{t('reviewBooking.' + (isLoading ? 'loadingButton' : 'agreeButton'))}}
          </app-icon-button>
        </div>
        <div class="error-message-container"
          *ngIf="reservationError">
          <app-notification
            iconName="close"
            stroked="textError"
            [isThreeDots]="false">
            <div class="error-message break-spaces text-1-1-500"
              [innerHTML]="reservationError">
            </div>
          </app-notification>
        </div>
        <small class="text-center text-0_75-1-500">
          {{t('reviewBooking.warning')}}
        </small>
      </div>
    </div>
  </div>
  <app-anchor type="bookingButton"></app-anchor>
  <ng-template #payment>
    <div class="frame-container flex-column">
      <div class="main-header flex bc-header padding-less">
        <div class="resort-logo">
          <img loading="lazy" [src]="resort?.logo?.thumbnailUrl" *ngIf="resort?.logo?.thumbnailUrl" />
        </div>
      </div>
      <p class="flex flex-wrap text-center margin-top-half margin-bottom-less"
        *ngIf="resort?.phone"
        [innerHtml]="t('page.payment.warning', { value: resort?.phone })">
      </p>
      <iframe *ngIf="paymentPageUrl"
        [src]="paymentPageUrl | safe: 'resourceUrl'"
        width="100%"
        height="100%"
        class="padding-bottom flex-1"
        frameborder="0">
      </iframe>
    </div>
  </ng-template>
  <app-modal-panel-v2
    [isActive]="isExtraTermsShown"
    positionHorizontal="center"
    positionVertical="center"
    [maxWidth]="500">
    <ng-template #header>
      <div class="text-1_25-1_25-600 color-textPrimary"
        [innerHtml]="extraTerms?.header">
      </div>
    </ng-template>
    <ng-template #content>
      <div class="text-1-1-500 color-textPrimary"
        [innerHtml]="extraTerms?.content">
      </div>
    </ng-template>
    <ng-template #footer>
      <div class="flex-between gap">
        <button mat-raised-button
          color="secondary"
          class="select-button"
          (click)="declineExtraTerms()">
          <span class="color-textPrimary">{{t('button.cancel')}}</span>
        </button>
        <button mat-raised-button
          color="primary"
          class="select-button"
          (click)="confirmExtraTerms()">
          {{t('reviewBooking.agreeExtraTermsButton' )}}
        </button>
      </div>
    </ng-template>
  </app-modal-panel-v2>
</ng-container>
<ng-template #termsAndConditions>
  <app-terms-and-conditions></app-terms-and-conditions>
</ng-template>

@if (isLoading){
<app-loading-screen section="reservation"></app-loading-screen>
}