import { Component, Input } from '@angular/core';
import { sectionChange } from '@shared/consts/animations';
import { AnimationComponentBase } from '@shared/base/animation-component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';

@Component({
  selector: 'app-section-toggler',
  templateUrl: './section-toggler.component.html',
  styleUrls: ['./section-toggler.component.scss'],
  animations: [sectionChange],
  standalone: false
})
export class SectionTogglerComponent extends AnimationComponentBase {
  @Input() isOnlyForTablet = true;
  @Input() isHidden = false;

  constructor(
    services: ComponentBaseService
  ) {
    super(services);
  }

  toggleIsHidden() {
    this.isHidden = !this.isHidden;
    this._animate();
  }
}
