import { Component, ElementRef, EventEmitter, HostBinding, Output, ViewChild } from '@angular/core';
import { Country, MembershipProfile, TravelCompanion } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';
import { arrayToRecord } from '@shared/common';
import { BreadCrumbsItem } from '@shared/components-v2/common/bread-crumbs/bread-crumbs.component';
import { SelectOption } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { MemberService } from '@member/member.service';

const profileMenu = ['basic', 'contact', 'traveller'] as const
export type ProfileMenuItem = typeof profileMenu[number];

@Component({
  selector: 'app-member-profile-v2',
  templateUrl: './member-profile.component.html',
  styleUrls: ['./member-profile.component.scss'],
  standalone: false
})
export class MemberProfileV2Component extends ComponentBase {
  constructor(
    private readonly _member: MemberService,
    service: ComponentBaseService
  ) {
    super(service);
    this.pushSub(
      _member.onMemberChanged(member => {
        this.profile = member.profile;
        this.resetView();
      }),
      //_member.onInit().subscribe(() => this.init()),
      _member.getCountries().subscribe(countries => {
        this.countries = countries;
        this.resetView();
        this.setBreadCrumbs();
      }),
      _member.loadTravelers().subscribe({
        next: result => this.travelers = result,
        error: ex => this.errorLoadTravellers = this._member.getMembershipError(ex),
        complete: () => this.isLoadTravelers = false
      })
    );
  }

  profile = this._member.profile;
  countries: Country[] = [];
  @HostBinding('class') hostClasses = ['w-100', 'flex-stretch-stretch', 'h-100-min'];
  @Output() menuItemSelected = new EventEmitter();
  @ViewChild('topRow') topRow?: ElementRef;
  basic!: SelectOption[];
  contact!: SelectOption[];
  travelers: TravelCompanion[] = [];
  traveller?: TravelCompanion;
  selectedMenuItem?: ProfileMenuItem;
  isLoadTravelers = true;
  breadCrumbs: BreadCrumbsItem[] = [];
  errorLoadTravellers?: string;

  readonly profileMenuItems = arrayToRecord<ProfileMenuItem>(profileMenu);

  // label => is full
  fullRow: Record<string, boolean> = {
    'address': true
  };

  resetView() {
    const {
      firstName, lastName, dob, phone, email, address1, state, zip, country, city
    } = this.profile || new MembershipProfile();

    const countryLabel = this.countries.find(item => item.twoLetterCode == country)?.name || country;

    this.basic = [
      { label: 'name', value: [firstName, lastName].filter(item => item).join(' ') },
      { label: 'dob', value: dob },
      //{ label: 'needs', value: remarks },
      //{ label: 'bio', value: undefined },
      { label: 'gender', value: undefined }
    ];

    this.contact = [
      { label: 'phone', value: phone },
      { label: 'email', value: email },
      { label: 'emergency', value: undefined },
      { label: 'address', value: [address1, city, state, zip, countryLabel].filter(item => item).join(', ') }
    ];
  }

  init() {
    this.setBreadCrumbs();
  }

  setBreadCrumbs() {
    this.breadCrumbs = [{ caption: 'caption', action: () => this.backToMainProfile() }];

    switch (this.selectedMenuItem) {
      case (this.profileMenuItems.traveller):
        this.breadCrumbs.push({ caption: this.traveller ? 'travelers.updateTraveller' : 'travelers.addTraveller' });
        break;
      case (this.profileMenuItems.basic):
        this.breadCrumbs.push({ caption: 'basic.header' });
        break;
      case (this.profileMenuItems.contact):
        this.breadCrumbs.push({ caption: 'contact.header' });
        break;
    }
  }

  backToMainProfile() {
    this.traveller = undefined;
    this.selectedMenuItem = undefined;
    this.setBreadCrumbs();
    this.scrollToTop();
  }

  scrollToTop() {
    (this.topRow?.nativeElement as HTMLElement)?.scrollIntoView({ behavior: 'smooth' });
  }

  toggleEdit(item: ProfileMenuItem) {
    this.selectedMenuItem = item === this.selectedMenuItem ? undefined : item;
    this.setBreadCrumbs();
  }
  //#endregion

  //#region Travellers
  selectTraveller(traveller?: TravelCompanion) {
    this.traveller = traveller;
    this.toggleEdit(this.profileMenuItems.traveller);
    this.menuItemSelected.emit();
  }

  closeTravelerProfile() {
    this._member.loadTravelers().subscribe(result => this.travelers = result);
    this.toggleEdit(this.profileMenuItems.traveller);
    this.backToMainProfile();
  }
  //#endregion
}
