<ng-container *transloco="let t">
  <div class="page-container">
    <div class="page-content"
      [class.with-rate-plan-type-switcher]="header.availableRatePlanType">
      <app-header #header></app-header>
      <div class="under-header"></div>
      <div class="page-section">
        <div class="flex">
          <div class="main-container">
            <div class="section">
              <div class="section-header">
                <div class="section-header-text">
                  {{t('page.booking.header')}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-notification-prices class="page-section"></app-notification-prices>
      <div class="flex"
        *ngIf="!isBookingInitialized">
        <div class="main-container">
          <div class="content-container spaceless">
            <div>{{t('notification.booking.checkAbandonedCart')}}</div>
          </div>
        </div>
      </div>
      <app-two-columns
        *ngIf="isBookingInitialized"
        rightColumnMaxWidth="half">
        <ng-template #leftColumn>
          <div class="hidden-tablet-min space-default">
            <div class="cart-container flex-column full-size">
              <app-cart-viewer [isOnlyMobileView]="true"></app-cart-viewer>
              <app-cart-total></app-cart-total>
            </div>
          </div>
          <div class="under-column">
            <app-notification-signup class="message" [isOnlyMobileView]="true"></app-notification-signup>
            <app-manage-booking></app-manage-booking>
            <app-guests></app-guests>
            <app-flight-info class="hide-if-empty"></app-flight-info>
            <app-protect-vacation class="hide-if-empty"></app-protect-vacation>
            <app-payment-plan></app-payment-plan>
            <app-review-booking></app-review-booking>
          </div>
        </ng-template>
        <ng-template #rightColumn>
          <div class="cart-container flex-column">
            <app-cart-viewer [isOnlyMobileView]="true"></app-cart-viewer>
            <app-cart-total></app-cart-total>
          </div>
        </ng-template>
      </app-two-columns>
    </div>
    <app-footer></app-footer>
  </div>
</ng-container>
