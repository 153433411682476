<ng-container *transloco="let t">
  <div class="flex-column bc-default padding w-100">
    <app-bread-crumbs-v2
      class="padding-top"
      [items]="breadCrumbs"
      section="member.rewards">
    </app-bread-crumbs-v2>
    <div class="text-1-1_5-400 margin-vertical">
      {{t('member.rewards.description')}}
    </div>
    @if(isLoading){
    <div class="flex-column gap margin-top">
      <app-loading-icon size="button">
      </app-loading-icon>
      <div class="flex text-1-1-500 color-selected">
        {{t('member.rewards.loading')}}
      </div>
    </div>
    }
    @if(currentLevel){
    <div class="text-1_125-2-500">
      Current loyalty level:
    </div>
    <ng-container *ngTemplateOutlet="rewards; context: { level: currentLevel }">
    </ng-container>
    }
    @if(nextLevel){
    <div class="text-1_125-2-500 margin-top">
      Next loyalty level:
    </div>
    <ng-container *ngTemplateOutlet="rewards; context: { level: nextLevel }">
    </ng-container>
    }
  </div>
  <ng-template #rewards
    let-level="level">
    <div class="margin-half padding-horizontal border box-shadow"
      [ngClass]="['border-' + levelSettings[level.code].color +'-double']">
      <div class="flex-end">
        <div class="text-1_25-2-500"
          [ngClass]="['color-' + levelSettings[level.code].color]">
          {{level.name}} ({{level.code}})
        </div>
        @if(level.imagePath){
        <div>
          <img [src]="level.imagePath" [alt]="level.code">
        </div>
        }
      </div>

      @if(level.code && benefits[level.code]){
      <div [class.margin-bottom]="benefits[level.code].isAnyBenefit">
        <i class="flex-column gap-half">
          @if(benefits[level.code].monetaryDiscount){
          <div>
            <b class="color-textSuccessPrice">-{{benefits[level.code].monetaryDiscount | appCurrency}}</b> discount per stay!
          </div>
          }
          @if(benefits[level.code].percentageDiscount){
          <div>
            <b class="color-textSuccessPrice">-{{benefits[level.code].percentageDiscount}}%</b> discount per stay!
          </div>
          }
          @if(benefits[level.code].resortCreditAmount){
          <div>
            <b class="color-textSuccessPrice">+{{benefits[level.code].resortCreditAmount | appCurrency}}</b> resort credit!
          </div>
          }
          @for (perk of benefits[level.code].perks; track $index) {
          <div>
            {{perk}}
          </div>
          }
        </i>
      </div>
      }
    </div>
  </ng-template>
</ng-container>